import { useEffect, useState } from "react";

import { color } from "metabase/lib/colors";
import { useDispatch, useSelector } from "metabase/lib/redux";
import {
  setBaselineSegment,
  setMeasuredCompleted,
  setMeasuredOption,
  setMeasuredOvertime,
  setSignificanceLevelData,
} from "metabase/query_builder/actions";
import {
  getBaselineSegment,
  getMeasuredCompleted,
  getMeasuredOption,
  getMeasuredOvertime,
  getSignificanceLevel,
} from "metabase/query_builder/selectors";
import { Icon } from "metabase/ui";

import { GroupContainer } from "../components/GroupContainer";
import { GroupSelect } from "../components/GroupSelect";
import { SelectModal } from "../components/SelectModal";

import {
  DashboardTextInput,
  MeasuredTitle,
  SelectButton,
  SignificanceContainer,
  StyledButton,
  StyledTitle,
  TabsContainer,
  WarningHeader,
  WarningText,
  WarningTitle,
  WarningWindow,
} from "./MeasuredAsSection.styled";

export const MeasuredAsSection = () => {
  const dispatch = useDispatch();

  const measuredOption = useSelector(getMeasuredOption);
  const measuredCompleted = useSelector(getMeasuredCompleted);
  const baseline = useSelector(getBaselineSegment);
  const significance = useSelector(getSignificanceLevel);
  const overtime = useSelector(getMeasuredOvertime);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const modalValues = ["days", "hours", "minutes", "seconds"];
  const tabsList = [
    {
      value: "Conversion",
      path: "/v2/datasets/funnel/conversion",
    },
    {
      value: "Over Time",
      path: "/v2/datasets/funnel/over_time",
    },
    {
      value: "Time to Convert",
      path: "/v2/datasets/funnel/conversion",
    },
    {
      value: "Significance",
      path: "/v2/datasets/funnel/significance",
    },
  ];
  const overtimeOptions = [
    { id: 1, value: "hour", label: "hour" },
    { id: 2, value: "day", label: "day" },
    { id: 3, value: "week", label: "week" },
    { id: 4, value: "month", label: "month" },
    { id: 5, value: "quarter", label: "quarter" },
    { id: 6, value: "year", label: "year" },
  ];
  const setInitialActiveTab = tabsList.findIndex(
    tab => tab.path === measuredOption.path,
  );

  const [activeTab, setActiveTab] = useState(setInitialActiveTab);

  const overtimeOption = tabsList[1].path;
  const significanceOption = tabsList[3].path;

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const setInitialActiveTab = tabsList.findIndex(
      tab => tab.path === measuredOption.path,
    );

    setActiveTab(setInitialActiveTab);
  }, [measuredOption.path]);

  const handleCompletedSave = (
    amount: number,
    type: string,
    value: number,
    overtime?: string,
  ) => {
    dispatch(
      setMeasuredCompleted({
        amount,
        type,
        value,
        overtime,
      }),
    );
  };

  return (
    <GroupContainer>
      <>
        <StyledTitle>{"Measuerd As"}</StyledTitle>
        <SelectButton onClick={onModalOpen}>
          {`Completed within ${measuredCompleted.amount} ${measuredCompleted.type}`}
          <Icon name="chevrondown" size={"0.8rem"} color={color("brand")} />
        </SelectButton>
        {measuredOption.path === overtimeOption && (
          <GroupSelect
            value={overtime}
            data={overtimeOptions}
            onChange={value => {
              if (value !== undefined) {
                dispatch(setMeasuredOvertime(value));
              }
            }}
          />
        )}

        {measuredOption.path === significanceOption && (
          <>
            <SignificanceContainer>
              <>
                <MeasuredTitle>Baseline segment number</MeasuredTitle>
                <DashboardTextInput
                  type="number"
                  value={baseline}
                  onChange={value => {
                    if (value !== undefined) {
                      dispatch(setBaselineSegment(value));
                    }
                  }}
                  placeholder={"Baseline segment number"}
                />
              </>
              <>
                <MeasuredTitle>Significance level</MeasuredTitle>
                <DashboardTextInput
                  type="number"
                  value={significance}
                  onChange={value => {
                    if (value !== undefined) {
                      dispatch(setSignificanceLevelData(value));
                    }
                  }}
                  placeholder={"Significance level"}
                  step={0.05}
                  defaultValue={significance}
                  min={0}
                  precision={2}
                  parser={value =>
                    value.replace(/[^\d.,]/g, "").replace(",", ".")
                  }
                  formatter={value => {
                    if (value === "") {
                      return "";
                    }
                    const numValue = parseFloat(value.replace(/[^\d.]/g, ""));
                    return isNaN(numValue) ? "" : numValue.toFixed(2);
                  }}
                />
              </>
            </SignificanceContainer>
            <WarningWindow>
              <WarningHeader>
                <Icon name="information" size={16} color={color("text-dark")} />
                <WarningTitle>
                  Start by selecting two events and two user segments
                </WarningTitle>
              </WarningHeader>
              <WarningText>
                Choose any two steps to track conversion and multiple segments
                to compare performance against your Baseline. By default, the
                first segment will be considered your Baseline but you can
                change this in the third module.
              </WarningText>
            </WarningWindow>
          </>
        )}
        <TabsContainer>
          {tabsList.map((tab, index) => {
            return (
              <StyledButton
                key={index}
                isActive={activeTab === index ? true : false}
                type="button"
                disabled={index === 2 ? true : false}
                onClick={() => {
                  setActiveTab(index);
                  dispatch(setMeasuredOption(tab));
                }}
              >
                {tab.value}
              </StyledButton>
            );
          })}
        </TabsContainer>
        <SelectModal
          values={modalValues}
          onSelect={handleCompletedSave}
          isOpen={isModalOpen}
          onClose={onModalClose}
        />
      </>
    </GroupContainer>
  );
};
