import { useSelector } from "metabase/lib/redux";
import { getDashboardMode } from "metabase/query_builder/selectors";
import { Icon } from "metabase/ui";

import { DashboardMode } from "../../types/enum";

import {
  CardHeaderDelete,
  CardHeaderNumber,
  CardHeaderTitle,
  CardHeaderWrapper,
  IconWrapper,
} from "./CardHeader.styled";

interface ICardHeaderProps {
  children: JSX.Element | string;
  index: number | string;
  isHover: boolean;
  onDelete: (cardId: string) => void;
  cardId: string;
  isAlphabetMode: boolean;
  isDragging: boolean;
}

export const CardHeader = ({
  children,
  index,
  isHover,
  onDelete,
  cardId,
  isAlphabetMode,
  isDragging,
}: ICardHeaderProps) => {
  const dashboardMode = useSelector(getDashboardMode);
  const cardValue = isAlphabetMode ? index + "." : "#" + (Number(index) + 1);

  return (
    <CardHeaderWrapper>
      <CardHeaderNumber isHover={isHover || isDragging}>
        {cardValue}
      </CardHeaderNumber>
      <CardHeaderTitle>
        {dashboardMode === DashboardMode.Segmentation && (
          <IconWrapper>
            <Icon name="pulse" size={10} color="white" />
          </IconWrapper>
        )}
        {children}
      </CardHeaderTitle>
      <CardHeaderDelete onClick={() => onDelete(cardId)}>
        <Icon name={"trash"} size={14} />
      </CardHeaderDelete>
    </CardHeaderWrapper>
  );
};
