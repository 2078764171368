import { useEffect, useState } from "react";

import { color } from "metabase/lib/colors";
import { useDispatch, useSelector } from "metabase/lib/redux";
import {
  setCurrentProperties,
  setSegmentProperties,
} from "metabase/query_builder/actions";
import {
  getCurrentSegments,
  getSegmentPropertiesList,
} from "metabase/query_builder/selectors";
import { Icon, Modal, Title } from "metabase/ui";
import type { NativeDatasetQuery } from "metabase-types/api";

import { useGetDatasetQuery } from "../../api/dashboardApi";
import type {
  IEventProperty,
  ISegmentsEndpoints,
} from "../../types/interfaces";
import { mapApiData } from "../../utils/mapApiData";
import { CancelButton } from "../CancelButton";
import { ModalCheckboxes } from "../ModalCheckboxes";
import { SaveButton } from "../SaveButton";

import {
  DashboardModalFooter,
  DashboardModalHeader,
  DashboardTextInput,
  EventsModalContent,
  ModalCloseButton,
  ModalContent,
  StyledLoader,
} from "./SegmentFilterModal.styled";

interface IFilterData {
  title: string;
  options: IEventProperty[];
}

interface IFilterModalProps {
  data: IFilterData;
  isOpen: boolean;
  onClose: () => void;
  additionalCancelOption?: boolean;
  onSave: (cardId: string, properties: string[]) => void;
  cardId?: string;
  endpoints: ISegmentsEndpoints;
}

export const SegmentFilterModal = ({
  data,
  isOpen,
  onClose,
  additionalCancelOption,
  onSave,
  cardId,
  endpoints,
}: IFilterModalProps) => {
  const dispatch = useDispatch();
  const currentSegments = useSelector(getCurrentSegments);
  const [searchValue, setSearchValue] = useState("");
  const [activeFilters, setActiveFilters] = useState([]);

  const payloadPropertiesQuery = `{
    "path": "${endpoints.filters}",
    "method": "post",
    "body": {},
    "headers": {}
  }`;

  const nativePropertiesQuery: NativeDatasetQuery = {
    native: {
      query: payloadPropertiesQuery,
    },
    type: "native",
    database: 2,
  };

  const propertiesData = useGetDatasetQuery(nativePropertiesQuery);

  const segmentProperties = useSelector(getSegmentPropertiesList);

  const currentPropertiesList = currentSegments?.find(
    item => item.id === cardId,
  )?.filters;

  const formattedKeys = segmentProperties?.data?.data?.cols?.map(
    col => col.name,
  );
  const formattedValues = segmentProperties?.data?.data?.rows;

  const eventsData = mapApiData(formattedKeys, formattedValues);

  const filteredPropertiesNew = eventsData?.filter(item =>
    item?.display?.toString().toLowerCase().includes(searchValue.toLowerCase()),
  );

  const fetchTestProperties = () => {
    dispatch(setSegmentProperties(propertiesData));

    const formattedKeys = propertiesData?.data?.data?.cols?.map(
      col => col.name,
    );
    const formattedValues = propertiesData?.data?.data?.rows;

    const eventsData = mapApiData(formattedKeys, formattedValues);
    dispatch(setCurrentProperties({ eventId: cardId, properties: eventsData }));
  };

  useEffect(() => {
    if (propertiesData.data !== null) {
      fetchTestProperties();
    }
  }, [propertiesData.data]);

  return (
    <Modal.Root opened={isOpen} onClose={() => setSearchValue("")}>
      <Modal.Overlay />
      <ModalContent>
        <DashboardModalHeader>
          <Title order={2} size="1rem" lh="1rem" color="inherit">
            {data.title}
          </Title>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setSearchValue("");
            }}
          />
        </DashboardModalHeader>
        <DashboardTextInput
          type="search"
          icon={<Icon name="search" size={16} />}
          miw={400}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={"Search…"}
        />
        <EventsModalContent>
          {!eventsData || eventsData?.length === 0 ? (
            <StyledLoader size="lg" c={color("brand")} />
          ) : (
            <ModalCheckboxes
              onCheckboxChange={cards => setActiveFilters(cards)}
              values={filteredPropertiesNew}
              currentProperties={currentPropertiesList}
              isOpen={isOpen}
            />
          )}
        </EventsModalContent>
        <DashboardModalFooter fullPageModal={false} formModal={true}>
          {additionalCancelOption && <CancelButton />}
          <SaveButton
            onClick={() => {
              onSave(cardId, activeFilters);
              onClose();
            }}
          />
        </DashboardModalFooter>
      </ModalContent>
    </Modal.Root>
  );
};
