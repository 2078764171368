import styled from "@emotion/styled";

import { ModalFooter } from "metabase/components/ModalContent";
import { color } from "metabase/lib/colors";
import { Loader, Modal, TextInput, Title } from "metabase/ui";

interface IModalListItemProps {
  isActive?: boolean;
}

export const ModalContent = styled(Modal.Content)`
  max-width: 640px;
`;

export const ModalCloseButton = styled(Modal.CloseButton)`
  color: ${color("text-medium")};
`;

export const DashboardTextInput = styled(TextInput)`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid ${color("border")};
  margin-right: 0;

  input {
    border-radius: 6px;
    background-color: ${color("bg-light")};
  }
`;

export const ConditionContent = styled.div`
  height: 500px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1.5rem;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    min-height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #a4aab8;
  }
`;

export const SelectedContent = styled.div`
  position: relative;
  height: 420px;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 1.5rem;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    min-height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #a4aab8;
  }
`;

export const DashboardModalFooter = styled(ModalFooter)`
  padding-top: 1.5rem;
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  padding-top: 1rem;
`;

export const StyledModalList = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const ModalListItem = styled.li<IModalListItemProps>`
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 700;
  padding: 0.5rem 1.5rem;
  padding-right: 1rem;
  color: ${color("text-dark")};
  background-color: ${props => (props.isActive ? "#eaf5fb" : "transparent")};
  cursor: pointer;

  &:hover {
    background-color: #eaf5fb;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${color("border")};
`;

export const ModalConditionColumn = styled.div`
  flex-basis: 245px;
  border-right: 1px solid ${color("border")};
`;

export const ModalSelectedColumn = styled.div`
  flex-basis: 395px;
`;

export const ConditionTitle = styled(Title)`
  padding-top: 1.2rem;
  padding-bottom: 0.7rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

export const SelectedHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0.7rem 1rem;
  padding-left: 1.5rem;
`;

export const SelectedGroup = styled.div`
  max-height: 230px;
  border-bottom: 1px solid ${color("border")};

  overflow: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    min-height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #a4aab8;
  }
`;

export const SelectedTitle = styled(Title)`
  padding: 0.7rem 1.5rem;
  margin-bottom: 0.7rem;
`;

export const ClearButton = styled.button`
  margin-left: auto;
  font-size: 0.8rem;
  line-height: 0.9rem;
  font-weight: 900;
  color: ${color("brand")};
  cursor: pointer;
`;

export const CopyButton = styled.button`
  padding: 0.35rem 1rem;
  border: 1px solid ${color("border")};
  margin-left: 1rem;
  font-size: 0.8rem;
  line-height: 0.9rem;
  font-weight: 900;
  color: ${color("text-dark")};
  border-radius: 8px;
  cursor: pointer;
`;

export const CheckboxContainer = styled.div`
  padding-left: 32px;
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  padding-right: 8px;

  &:hover {
    background-color: #eaf5fb;
  }
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
