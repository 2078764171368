import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useRef, useState } from "react";

import { useDispatch, useSelector } from "metabase/lib/redux";
import {
  removeSegmentCohort,
  removeSegmentFilter,
  updateSegmentCohorts,
  updateSegmentFilters,
} from "metabase/query_builder/actions";
import { getCohortValues, getCohorts } from "metabase/query_builder/selectors";
import { Icon } from "metabase/ui";

import type {
  ICohortValue,
  ISegmentItem,
  ISegmentsEndpoints,
} from "../../types/interfaces";
import { CardFooter } from "../CardFooter";
import { CardHeader } from "../CardHeader";
import { CohortCardContent } from "../CohortCardContent";
import { CohortsModal } from "../CohortsModal";
import { SegmentFilterModal } from "../SegmentFilterModal";
import { SegmentParamsModal } from "../SegmentParamsModal";

import { DraggableButton, StyledGroupCard } from "./SegmentsGroupCard.styled";

interface IGroupCardProps {
  item: ISegmentItem;
  index: number;
  withOpacity?: boolean;
  isDragging?: boolean;
  onDelete: (cardId: string) => void;
  endpoints: ISegmentsEndpoints;
}

export const SegmentsGroupCard = ({
  item,
  index,
  isDragging,
  onDelete,
  endpoints,
}: IGroupCardProps) => {
  const dispatch = useDispatch();
  const { id, value, cohorts, filters } = item;
  const cohortsList = useSelector(getCohorts);
  const cohortsFinalList = cohortsList.map((cohort: ICohortValue) => {
    return {
      id: cohort.id,
      type: cohort.load_date,
      value: cohort.load_date,
      display: cohort.load_date,
    };
  });
  const cohortValuesList = useSelector(getCohortValues);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const [isParamsModalOpen, setIsParamsModalOpen] = useState<boolean>(false);
  const [activeProperty, setActiveProperty] = useState<string>("");
  const [isCardHover, setIsCardHover] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isCohortModalOpen, setIsCohortModalOpen] = useState(false);

  const idCounter = useRef(0);

  const generateId = (): string => {
    return `card_${idCounter.current++}`;
  };

  const style = {
    transition: transition || undefined,
    transform: CSS.Transform.toString(transform),
  };

  const onParamsModalClose = () => {
    setIsParamsModalOpen(false);
    setActiveProperty("");
  };

  const onParamsModalOpen = (propertyId: string) => {
    setIsParamsModalOpen(true);
    setActiveProperty(propertyId);
  };

  const handleCardHover = () => {
    setIsCardHover(true);
  };

  const handleCardLeave = () => {
    setIsCardHover(false);
  };

  const onFilterModalClose = () => {
    setIsFilterModalOpen(false);
  };

  const onFilterModalOpen = () => {
    setIsFilterModalOpen(true);
  };

  const onCohortModalClose = () => {
    setIsCohortModalOpen(false);
  };

  const onCohortModalOpen = () => {
    setIsCohortModalOpen(true);
  };

  const onFilterModalSave = (cardId: string, properties: string[]) => {
    dispatch(updateSegmentFilters({ cardId, properties }));
  };

  const onCohortModalSave = (cardId: string, properties: string[]) => {
    const newCohort = {
      id: generateId(),
      cohortsList: properties,
    };

    dispatch(updateSegmentCohorts({ cardId, newCohort }));
  };

  const removeCohort = (cardId: string, propertyId: string) => {
    dispatch(removeSegmentCohort(cardId, propertyId));
  };

  const removeFilter = (cardId: string, propertyId: string) => {
    dispatch(removeSegmentFilter(cardId, propertyId));
  };

  return (
    <>
      <StyledGroupCard
        ref={setNodeRef}
        style={style}
        withOpacity={isDragging}
        onMouseOver={handleCardHover}
        onMouseOut={handleCardLeave}
      >
        <DraggableButton
          {...attributes}
          {...listeners}
          isDragging={isDragging}
          isHover={isCardHover}
        >
          <Icon name={"draggable"} size={16} />
        </DraggableButton>
        <CardHeader
          isHover={isCardHover}
          index={index}
          onDelete={onDelete}
          cardId={item.id}
          isAlphabetMode={false}
          isDragging={isDragging}
        >
          {value}
        </CardHeader>
        <CohortCardContent
          data={cohorts}
          onParamsClick={onParamsModalOpen}
          onCohortClick={onCohortModalOpen}
          onCohortDelete={removeCohort}
          onFilterDelete={removeFilter}
          cardId={id}
          filters={filters}
        ></CohortCardContent>
        <CardFooter
          onFilterChange={onFilterModalOpen}
          onGroupChange={onCohortModalOpen}
          onCohortChange={onCohortModalOpen}
        ></CardFooter>
      </StyledGroupCard>

      <SegmentParamsModal
        values={
          cohortValuesList.find(
            (item: ICohortValue) => item.id === activeProperty,
          )?.values
        }
        isOpen={isParamsModalOpen}
        onClose={onParamsModalClose}
        additionalCancelOption
        cardId={id}
        propertyId={activeProperty}
        propertyValues={cohortValuesList}
        endpoints={endpoints}
      />
      <SegmentFilterModal
        data={{ title: "Filter by", options: cohortsFinalList }}
        isOpen={isFilterModalOpen}
        onClose={onFilterModalClose}
        onSave={onFilterModalSave}
        cardId={id}
        endpoints={endpoints}
      />
      <CohortsModal
        isOpen={isCohortModalOpen}
        onClose={onCohortModalClose}
        onSave={onCohortModalSave}
        cardId={id}
      />
    </>
  );
};
