import styled from "@emotion/styled";

import { ModalFooter, ModalHeader } from "metabase/components/ModalContent";
import { color } from "metabase/lib/colors";
import { Loader, Modal, TextInput, Title } from "metabase/ui";

export const ModalContent = styled(Modal.Content)`
  max-width: 560px;
`;

export const StyledTitle = styled(Title)`
  font-size: 1rem;
  line-height: 1rem;
`;

export const DashboardModalHeader = styled(ModalHeader)`
  padding-top: 1.5rem;
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid ${color("border")};
`;

export const ModalCloseButton = styled(Modal.CloseButton)`
  color: ${color("text-medium")};
`;

export const DashboardTextInput = styled(TextInput)`
  padding: 0.5rem 1.5rem;
  border-bottom: 1px solid ${color("border")};
  margin-right: 0;

  input {
    border-radius: 6px;
    background-color: ${color("bg-light")};
  }
`;

export const EventsModalContent = styled.div`
  height: 300px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${color("border")};
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    min-height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #a4aab8;
  }
`;

export const DashboardModalFooter = styled(ModalFooter)`
  padding-top: 1.5rem;
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  padding-top: 1rem;
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
