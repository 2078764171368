import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "metabase/lib/redux";
import {
  setSegmentationFormula,
  setSegmentationMeasuredOption,
  setSegmentationMeasuredOvertime,
} from "metabase/query_builder/actions";
import {
  getSegmentationMeasuredOption,
  getSegmentationMeasuredOvertime,
} from "metabase/query_builder/selectors";

import { GroupContainer } from "../components/GroupContainer";
import { GroupSelect } from "../components/GroupSelect";

import {
  FormulaTextInput,
  MeasuredControlsContainer,
  MeasuredFormulaContainer,
  StyledApplyButton,
  StyledButton,
  StyledCloseButton,
  StyledMeasuredLink,
  StyledTitle,
  TabsContainer,
} from "./SegmentationMeasuredAsSection.styled";

export const SegmentationMeasuredAsSection = () => {
  const dispatch = useDispatch();

  const measuredOption = useSelector(getSegmentationMeasuredOption);
  const overtime = useSelector(getSegmentationMeasuredOvertime);

  const tabsList = [
    {
      value: "Uniques",
      path: "/v2/datasets/segmentation/standard",
    },
    {
      value: "Event Totals",
      path: "/v2/datasets/segmentation/over_time",
    },
    {
      value: "Active %",
      path: "/v2/datasets/segmentation/active_percent",
    },
    {
      value: "Average",
      path: "/v2/datasets/segmentation/avg_users",
    },
    {
      value: "Frequency",
      path: "/v2/datasets/segmentation/freq",
    },
  ];

  const setInitialActiveTab = tabsList.findIndex(
    tab => tab.path === measuredOption.path,
  );

  const [activeTab, setActiveTab] = useState(setInitialActiveTab);
  const [isFormulaActive, setIsFormulaActive] = useState(false);
  const [formulaValue, setFormulaValue] = useState("UNIQUES(A)");

  const overtimeOptions = [
    { id: 1, value: "hour", label: "hour" },
    { id: 2, value: "day", label: "day" },
    { id: 3, value: "week", label: "week" },
    { id: 4, value: "month", label: "month" },
    { id: 5, value: "quarter", label: "quarter" },
    { id: 6, value: "year", label: "year" },
    { id: 7, value: "Not selected", label: "Not selected" },
  ];

  const hasFormula =
    measuredOption.value === "Uniques" ||
    measuredOption.value === "Event Totals";

  const handleFormulaOpen = () => {
    setIsFormulaActive(true);
  };

  const handleFormulaClose = () => {
    setIsFormulaActive(false);
  };

  const handleFormulaSave = () => {
    dispatch(setSegmentationFormula({ formulaValue }));
  };

  useEffect(() => {
    const setInitialActiveTab = tabsList.findIndex(
      tab => tab.path === measuredOption.path,
    );

    setActiveTab(setInitialActiveTab);
  }, [measuredOption.path]);

  return (
    <GroupContainer>
      <>
        <StyledTitle>{"Measuerd As"}</StyledTitle>
        <GroupSelect
          value={overtime}
          data={overtimeOptions}
          onChange={value => {
            if (value !== undefined) {
              dispatch(setSegmentationMeasuredOvertime(value));
            }
          }}
        />
        <TabsContainer>
          {tabsList.map((tab, index) => {
            return (
              <StyledButton
                key={index}
                isActive={activeTab === index ? true : false}
                type="button"
                onClick={() => {
                  setActiveTab(index);
                  handleFormulaClose();
                  dispatch(setSegmentationMeasuredOption(tab));
                }}
              >
                {tab.value}
              </StyledButton>
            );
          })}
        </TabsContainer>
        {isFormulaActive && (
          <MeasuredFormulaContainer>
            <FormulaTextInput
              type="text"
              miw={400}
              value={formulaValue}
              onChange={e => setFormulaValue(e.target.value)}
              placeholder={"UNIQUES(A)"}
            />
            <MeasuredControlsContainer>
              <StyledCloseButton
                type="button"
                onClick={() => {
                  handleFormulaClose();
                  setFormulaValue("");
                }}
              >
                Close Formula
              </StyledCloseButton>
              <StyledApplyButton
                type="button"
                onClick={() => {
                  handleFormulaSave();
                  handleFormulaClose();
                }}
              >
                Apply Formula
              </StyledApplyButton>
            </MeasuredControlsContainer>
          </MeasuredFormulaContainer>
        )}
        {!isFormulaActive && hasFormula && (
          <StyledMeasuredLink type="button" onClick={handleFormulaOpen}>
            fx Formula
          </StyledMeasuredLink>
        )}
      </>
    </GroupContainer>
  );
};
