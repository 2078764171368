import { StyledGroupSelect } from "./GroupSelect.styled";

interface IData {
  value: string;
  label: string;
}

interface IGroupSelectProps {
  value: string;
  data: IData[];
  onChange: (value: string) => void;
}

export const GroupSelect = ({ value, data, onChange }: IGroupSelectProps) => {
  return (
    <StyledGroupSelect
      value={value}
      data={data}
      onChange={onChange}
      styles={{
        dropdown: {
          maxHeight: 100,
          padding: "10px",
          overflowY: "auto",
          color: "white",
          backgroundColor: "white",
        },
        item: {
          background: "white",
          borderColor: "white",
          marginBottom: "10px",
        },
        label: {
          background: "white",
        },
        rightSection: {
          color: "#509EE3",
          fill: "#509EE3",
          top: "5px",
          right: "8px",
          width: "0.8rem",
          height: "0.8rem",
        },
      }}
    ></StyledGroupSelect>
  );
};
