import { useContext, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "metabase/lib/redux";
import {
  addSegment,
  removeSegment,
  setCohortsValues,
} from "metabase/query_builder/actions";
import { getCurrentSegments } from "metabase/query_builder/selectors";
import type { NativeDatasetQuery } from "metabase-types/api";

import { usePostEventsNativeQueryMutation } from "../api/dashboardApi";
import { AddSegmentsModal } from "../components/AddSegmentsModal";
import { GroupButton } from "../components/GroupButton/GroupButton";
import { GroupContainer } from "../components/GroupContainer";
import { GroupLink } from "../components/GroupLink";
import { SegmentsGroupCards } from "../components/SegmentsGroupCards";
import { CardsContext } from "../contexts/CardsContext";
import type { IColsItem, ISegmentsEndpoints } from "../types/interfaces";
import { mapApiData } from "../utils/mapApiData";

import { StyledTitle } from "./SegmentBySection.styled";

interface ISegmentBySectionProps {
  endpoints: ISegmentsEndpoints;
}

export const SegmentBySection = ({ endpoints }: ISegmentBySectionProps) => {
  const [postNativeQuery] = usePostEventsNativeQueryMutation();
  const dispatch = useDispatch();
  const currentSegments = useSelector(getCurrentSegments);
  const SegmentContext = useContext(CardsContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const idCounter = useRef(0);

  const generateId = (): string => {
    return `card_${idCounter.current++}`;
  };

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const onModalOpen = () => {
    setIsModalOpen(true);
  };

  const addSegmentCard = (cardName: string) => {
    const newCard = {
      id: generateId(),
      value: cardName,
      cohorts: [],
      filters: [],
    };

    dispatch(addSegment(newCard));
  };

  const removeSegmentCard = (cardId: string) => {
    dispatch(removeSegment(cardId));
  };

  const fetchCohortsData = async () => {
    const payloadQuery = `{
      "path": "${endpoints.cohorts}",
      "method": "post",
      "body": {},
      "headers": {}
    }`;

    const nativeQuery: NativeDatasetQuery = {
      native: {
        query: payloadQuery,
      },
      type: "native",
      database: 2,
    };

    try {
      const response = await postNativeQuery(nativeQuery).unwrap();
      const formattedKeys = response?.data?.cols?.map(
        (col: IColsItem) => col.name,
      );
      const formattedValues = response?.data?.rows;
      const eventsData = mapApiData(formattedKeys, formattedValues);

      dispatch(setCohortsValues(eventsData));
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  useEffect(() => {
    fetchCohortsData();
  }, []);

  return (
    <GroupContainer>
      <>
        <StyledTitle>{"Segment by"}</StyledTitle>
        <CardsContext.Provider
          value={{ ...SegmentContext, groupOption: false, cohortOption: true }}
        >
          <SegmentsGroupCards
            onDelete={removeSegmentCard}
            endpoints={endpoints}
          />
        </CardsContext.Provider>
        {currentSegments.length > 0 ? (
          <GroupLink name={"Add segment"} onClick={onModalOpen} />
        ) : (
          <GroupButton name={"Add segment"} onClick={onModalOpen} />
        )}
        <AddSegmentsModal
          title={"Add Segments"}
          isOpen={isModalOpen}
          onClose={onModalClose}
          onSave={addSegmentCard}
        />
      </>
    </GroupContainer>
  );
};
