import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useState } from "react";

import { useDispatch } from "metabase/lib/redux";
import { updateEventProperties } from "metabase/query_builder/actions";
import { Icon } from "metabase/ui";

import type {
  IEventsEndpoints,
  IProperty,
  IPropertyValue,
} from "../../types/interfaces";
import { CardContent } from "../CardContent/CardContent";
import { CardFooter } from "../CardFooter";
import { CardHeader } from "../CardHeader";
import { FilterModal } from "../FilterModal";
import { GroupModal } from "../GroupModal";
import { ParamsModal } from "../ParamsModal";

import { DraggableButton, StyledGroupCard } from "./GroupCard.styled";

interface IGroupCard {
  id: string;
  display: string;
  value: string;
  properties: IProperty[];
  cardUniqueId: number;
}

interface IGroupCardProps {
  item: IGroupCard;
  index: number | string;
  onCohortChange?: () => void;
  withOpacity?: boolean;
  isDragging?: boolean;
  onDelete: (cardId: string) => void;
  onPropertyDelete: (cardId: string, propertyId: string) => void;
  propertyValues: IPropertyValue[];
  endpoints: IEventsEndpoints;
  isAlphabetMode: boolean;
}

export const GroupCard = ({
  item,
  index,
  isDragging,
  onCohortChange,
  onDelete,
  onPropertyDelete,
  endpoints,
  isAlphabetMode,
}: IGroupCardProps) => {
  const dispatch = useDispatch();
  const { id, value, properties, cardUniqueId } = item;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const [isParamsModalOpen, setIsParamsModalOpen] = useState<boolean>(false);
  const [activeProperty, setActiveProperty] = useState<string>("");
  const [isCardHover, setIsCardHover] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);

  const style = {
    transition: transition || undefined,
    transform: CSS.Transform.toString(transform),
  };

  const onParamsModalClose = () => {
    setIsParamsModalOpen(false);
    setActiveProperty("");
  };

  const onParamsModalOpen = (propertyId: string) => {
    setIsParamsModalOpen(true);
    setActiveProperty(propertyId);
  };

  const onGroupModalClose = () => {
    setIsGroupModalOpen(false);
  };

  const onGroupModalOpen = () => {
    setIsGroupModalOpen(true);
  };

  const handleCardHover = () => {
    setIsCardHover(true);
  };

  const handleCardLeave = () => {
    setIsCardHover(false);
  };

  const onFilterModalClose = () => {
    setIsFilterModalOpen(false);
  };

  const onFilterModalOpen = () => {
    setIsFilterModalOpen(true);
  };

  const onFilterModalSave = (cardId: string, properties: IGroupCard[]) => {
    dispatch(updateEventProperties({ cardId, properties }));
  };

  return (
    <>
      <StyledGroupCard
        ref={setNodeRef}
        style={style}
        withOpacity={isDragging}
        onMouseOver={handleCardHover}
        onMouseOut={handleCardLeave}
      >
        <DraggableButton
          {...attributes}
          {...listeners}
          isDragging={isDragging}
          isHover={isCardHover}
        >
          <Icon name={"draggable"} size={16} />
        </DraggableButton>
        <CardHeader
          isHover={isCardHover}
          index={index}
          onDelete={onDelete}
          cardId={item.id}
          isAlphabetMode={isAlphabetMode}
          isDragging={isDragging}
        >
          {value}
        </CardHeader>
        <CardContent
          data={properties}
          onParamsClick={onParamsModalOpen}
          onPropertyDelete={onPropertyDelete}
          cardId={id}
          cardUniqueId={cardUniqueId}
        ></CardContent>
        <CardFooter
          onFilterChange={onFilterModalOpen}
          onGroupChange={onGroupModalOpen}
          onCohortChange={onCohortChange}
        ></CardFooter>
      </StyledGroupCard>

      <ParamsModal
        isOpen={isParamsModalOpen}
        onClose={onParamsModalClose}
        additionalCancelOption
        cardId={id}
        propertyId={activeProperty}
        endpoints={endpoints}
        cardUniqueId={cardUniqueId}
      />
      <FilterModal
        data={{ title: "Filter by" }}
        isOpen={isFilterModalOpen}
        onClose={onFilterModalClose}
        onSave={onFilterModalSave}
        cardId={id}
        eventProperties={properties}
        endpoints={endpoints}
        cardUniqueId={cardUniqueId}
      />
      <GroupModal
        data={{ title: "Group by" }}
        isOpen={isGroupModalOpen}
        onClose={onGroupModalClose}
        cardId={id}
        endpoints={endpoints}
        cardUniqueId={cardUniqueId}
      />
    </>
  );
};
