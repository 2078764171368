import { assoc, merge } from "icepick";
import { handleActions } from "redux-actions";
import _ from "underscore";

import {
  EDIT_QUESTION,
  NAVIGATE_TO_NEW_CARD,
} from "metabase/dashboard/actions";
import TimelineEvents from "metabase/entities/timeline-events";
import { copy } from "metabase/lib/utils";

import {
  ADD_EVENT,
  ADD_SEGMENT,
  API_CREATE_QUESTION,
  API_UPDATE_QUESTION,
  CANCEL_DATASET_CHANGES,
  CANCEL_QUERY,
  CLEAR_EVENT_GROUP,
  CLEAR_GLOBAL_GROUP,
  CLEAR_OBJECT_DETAIL_FK_REFERENCES,
  CLEAR_QUERY_RESULT,
  CLOSE_QB,
  CLOSE_QB_NEWB_MODAL,
  CREATE_PUBLIC_LINK,
  DELETE_PUBLIC_LINK,
  DESELECT_TIMELINE_EVENTS,
  HIDE_TIMELINE_EVENTS,
  INITIALIZE_QB,
  GET_EVENTS_LIST,
  GET_EVENTS_PROPERTIES,
  LOAD_OBJECT_DETAIL_FK_REFERENCES,
  OPEN_DATA_REFERENCE_AT_QUESTION,
  QUERY_COMPLETED,
  QUERY_ERRORED,
  RELOAD_CARD,
  REMOVE_EVENT,
  REMOVE_EVENT_PROPERTY,
  REMOVE_SEGMENT,
  REMOVE_SEGMENT_COHORT,
  REMOVE_SEGMENT_FILTER,
  RESET_DASHBOARD_DATA,
  RESET_QB,
  RESET_ROW_ZOOM,
  RESET_UI_CONTROLS,
  RESET_MEASURED,
  RESORE_DASHBOARD_DATA,
  RUN_QUERY,
  SELECT_TIMELINE_EVENTS,
  SET_CARD_AND_RUN,
  SET_CURRENT_EVENT_FILTERS,
  SET_CURRENT_PROPERTIES,
  SET_CURRENT_STATE,
  SET_DASHBOARD_MODE,
  SET_DATA_REFERENCE_STACK,
  SET_DOCUMENT_TITLE,
  SET_DOCUMENT_TITLE_TIMEOUT_ID,
  SET_IS_SHOWING_TEMPLATE_TAGS_EDITOR,
  SET_EVENT_GROUP,
  SET_EVENT_GROUP_PROPERTIES,
  SET_CURRENT_EVENTS_ORDER,
  SET_EVENTS,
  SET_EVENTS_PROPERTIES,
  SET_EVENT_GROUP_FREQUENTLY_USED,
  SET_CURRENT_FILTERS,
  SET_CURRENT_FREQUENTLY_FILTERS,
  SET_CURRENT_GROUP_PROPERTIES,
  SET_CURRENT_GROUP_FREQUENTLY_PROPERTIES,
  SET_GLOBAL_FILTERS,
  SET_GLOBAL_FREQUENTLY_FILTERS,
  SET_MEASURED_COMPLETED,
  SET_MEASURED_OPTION,
  SET_MEASURED_OVERTIME,
  SET_SEGMENTATION_MEASURED_OPTION,
  SET_METADATA_DIFF,
  SET_MODAL_SNIPPET,
  SET_NATIVE_EDITOR_SELECTED_RANGE,
  SET_PARAMETER_VALUE,
  SET_SEGMENT_PROPERTIES,
  SET_SEGMENT_VALUES,
  SET_SEGMENTATION_FORMULA,
  SET_SEGMENTATION_MEASURED_OVERTIME,
  SET_SHOW_LOADING_COMPLETE_FAVICON,
  SET_SNIPPET_COLLECTION_ID,
  SET_SIGNIFICANCE_LEVEL_DATA,
  SET_BASELINE_SEGMENT,
  SET_COHORTS_VALUES,
  SET_EVENTS_PROPERTIES_VALUES,
  SET_UI_CONTROLS,
  SHOW_CHART_SETTINGS,
  SHOW_TIMELINE_EVENTS,
  SOFT_RELOAD_CARD,
  TOGGLE_DATA_REFERENCE,
  TOGGLE_IS_FIRST_DASHBOARD_INITIALIZATION,
  TOGGLE_SNIPPET_SIDEBAR,
  TOGGLE_TEMPLATE_TAGS_EDITOR,
  UPDATE_CURRENT_GROUP,
  UPDATE_EVENT_PROPERTIES,
  UPDATE_EVENTS_CARDS,
  UPDATE_EMBEDDING_PARAMS,
  UPDATE_ENABLE_EMBEDDING,
  UPDATE_QUESTION,
  UPDATE_PROPERTY_CONDITION,
  UPDATE_PROPERTY_INFO,
  UPDATE_SEGMENTS_CARDS,
  UPDATE_SEGMENT_COHORTS,
  UPDATE_SEGMENT_CONDITION,
  UPDATE_SEGMENT_FILTERS,
  UPDATE_SEGMENT_PROPERTY_CONDITION,
  ZOOM_IN_ROW,
  onCloseChartSettings,
  onCloseChartType,
  onCloseQuestionInfo,
  onCloseSidebars,
  onCloseSummary,
  onCloseTimelines,
  onEditSummary,
  onOpenChartSettings,
  onOpenChartType,
  onOpenQuestionInfo,
  onOpenTimelines,
} from "./actions";

const DEFAULT_UI_CONTROLS = {
  dataReferenceStack: null,
  isModifiedFromNotebook: false,
  isShowingDataReference: false,
  isShowingTemplateTagsEditor: false,
  isShowingNewbModal: false,
  isRunning: false,
  isQueryComplete: false,
  isShowingSummarySidebar: false,
  isShowingChartTypeSidebar: false,
  isShowingChartSettingsSidebar: false,
  isShowingQuestionInfoSidebar: false,
  isShowingTimelineSidebar: false,
  isNativeEditorOpen: false,
  initialChartSetting: null,
  isShowingRawTable: false, // table/viz toggle
  queryBuilderMode: false, // "view" | "notebook" | "dataset"
  previousQueryBuilderMode: false,
  snippetCollectionId: null,
  datasetEditorTab: "query", // "query" / "metadata"
};

const DEFAULT_LOADING_CONTROLS = {
  showLoadCompleteFavicon: false,
  documentTitle: "",
  timeoutId: "",
};

const DEFAULT_EVENTS_STATE = {
  events: [
    {
      id: "1",
      display: "event 1",
      value: "event 1",
    },
    {
      id: "2",
      display: "event 2",
      value: "event 2",
    },
    {
      id: "3",
      display: "event 3",
      value: "event 3",
    },
  ],
  segments: [
    {
      id: "1",
      display: "segment 1",
      value: "segment 1",
    },
    {
      id: "2",
      display: "segment 2",
      value: "segment 2",
    },
    {
      id: "3",
      display: "segment 3",
      value: "segment 3",
    },
  ],
  cohorts: [
    {
      id: "1",
      load_date: "cohort1",
    },
    {
      id: "2",
      load_date: "cohort2",
    },
    {
      id: "3",
      load_date: "cohort3",
    },
  ],
  cohortsValues: [],
  currentEvents: [],
  currentSegments: [],
  propertyValues: [
    { value: "animals", display: "animals" },
    { value: "theme", display: "theme" },
  ],
  cohortValues: [
    { value: "animals", load_date: "animals" },
    { value: "theme", load_date: "theme" },
  ],
  properties: [
    {
      id: "1",
      type: "string",
      value: "categoryTitle1",
      display: "categoryTitle1",
      values: [],
      condition: "",
      selected: [],
    },
    {
      id: "2",
      type: "empty",
      value: "categoryTitle2",
      display: "categoryTitle2",
    },
    {
      id: "3",
      type: "string",
      value: "categoryTitle3",
      display: "categoryTitle3",
    },
  ],
  frequentlyUsed: [
    {
      id: "1",
      type: "string",
      value: "categoryTitle1",
      display: "categoryTitle1",
    },
    {
      id: "2",
      type: "empty",
      value: "categoryTitle2",
      display: "categoryTitle2",
    },
  ],
  currentProperties: {
    condition: "",
  },
  measured: {
    completed: {
      amount: 1,
      type: "days",
      value: 86400,
      overtime: "week",
    },
    option: {
      value: "Conversion",
      path: "/v2/datasets/funnel/conversion",
    },
  },
  segmentationMeasured: {
    option: {
      value: "Uniques",
      path: "/v2/datasets/segmentation/standard",
    },
    overtime: "day",
  },
  currentGroup: {
    properties: [],
    frequentlyUsedProperties: [],
  },
  eventsPropertiesValues: [],
  eventsOrder: {
    id: 1,
    value: "In this order",
    label: "In this order",
    valueId: "this_order",
  },
  baseline: 1,
  significance: 0.05,
  globalGroup: {
    filters: [],
    frequentlyFilters: [],
  },
  dashboardMode: "tab-funnel",
  isFirstDashboardInitializaton: true,
  segmentationFormula: "",
};

const DEFAULT_DASHBOARD_STATE = {
  dashboardId: null,
  isEditing: false,
};

const DEFAULT_QUERY_STATUS = "idle";

const UI_CONTROLS_SIDEBAR_DEFAULTS = {
  isShowingSummarySidebar: false,
  isShowingChartSettingsSidebar: false,
  isShowingChartTypeSidebar: false,
  isShowingTimelineSidebar: false,
  isShowingQuestionInfoSidebar: false,
};

// this is used to close other sidebar when one is updated
const CLOSED_NATIVE_EDITOR_SIDEBARS = {
  isShowingTemplateTagsEditor: false,
  isShowingSnippetSidebar: false,
  isShowingDataReference: false,
  isShowingTimelineSidebar: false,
};

function setUIControls(state, changes) {
  const { queryBuilderMode: currentQBMode, ...currentState } = state;
  const { queryBuilderMode: nextQBMode, ...nextStateChanges } = changes;

  const isChangingQBMode = nextQBMode && currentQBMode !== nextQBMode;
  const isOpeningEditingQBMode = isChangingQBMode && nextQBMode !== "view";

  const queryBuilderMode = nextQBMode || currentQBMode;
  const previousQueryBuilderMode = isChangingQBMode
    ? currentQBMode
    : state.previousQueryBuilderMode;

  // Close all the sidebars when entering notebook/dataset QB modes
  const extraState = isOpeningEditingQBMode ? UI_CONTROLS_SIDEBAR_DEFAULTS : {};

  return {
    ...currentState,
    ...extraState,
    ...nextStateChanges,
    queryBuilderMode,
    previousQueryBuilderMode,
  };
}

export const uiControls = handleActions(
  {
    [SET_UI_CONTROLS]: {
      next: (state, { payload }) => setUIControls(state, payload),
    },

    [RESET_UI_CONTROLS]: {
      next: (state, { payload }) => DEFAULT_UI_CONTROLS,
    },

    [INITIALIZE_QB]: {
      next: (state, { payload }) => {
        return {
          ...state,
          ...DEFAULT_UI_CONTROLS,
          ...CLOSED_NATIVE_EDITOR_SIDEBARS,
          ...payload.uiControls,
        };
      },
    },

    [TOGGLE_DATA_REFERENCE]: {
      next: (state, { payload }) => ({
        ...state,
        ...CLOSED_NATIVE_EDITOR_SIDEBARS,
        isShowingDataReference: !state.isShowingDataReference,
      }),
    },
    [SET_DATA_REFERENCE_STACK]: {
      next: (state, { payload }) => ({
        ...state,
        dataReferenceStack: payload,
      }),
    },
    [OPEN_DATA_REFERENCE_AT_QUESTION]: {
      next: (state, { payload }) => {
        return payload
          ? {
              ...state,
              dataReferenceStack: payload,
              isShowingDataReference: true,
            }
          : state;
      },
    },
    [TOGGLE_TEMPLATE_TAGS_EDITOR]: {
      next: (state, { payload }) => ({
        ...state,
        ...CLOSED_NATIVE_EDITOR_SIDEBARS,
        isShowingTemplateTagsEditor: !state.isShowingTemplateTagsEditor,
      }),
    },
    [TOGGLE_SNIPPET_SIDEBAR]: {
      next: (state, { payload }) => ({
        ...state,
        ...CLOSED_NATIVE_EDITOR_SIDEBARS,
        isShowingSnippetSidebar: !state.isShowingSnippetSidebar,
        snippetCollectionId: null,
      }),
    },
    [SET_IS_SHOWING_TEMPLATE_TAGS_EDITOR]: {
      next: (state, { isShowingTemplateTagsEditor }) => ({
        ...state,
        ...CLOSED_NATIVE_EDITOR_SIDEBARS,
        isShowingTemplateTagsEditor,
      }),
    },
    [SET_NATIVE_EDITOR_SELECTED_RANGE]: (state, { payload }) => ({
      ...state,
      nativeEditorSelectedRange: payload,
    }),
    [SET_MODAL_SNIPPET]: (state, { payload }) => ({
      ...state,
      modalSnippet: payload,
    }),
    [SET_SNIPPET_COLLECTION_ID]: (state, { payload }) => ({
      ...state,
      snippetCollectionId: payload,
    }),
    [CLOSE_QB_NEWB_MODAL]: {
      next: (state, { payload }) => ({ ...state, isShowingNewbModal: false }),
    },

    [RUN_QUERY]: state => ({
      ...state,
      isRunning: true,
    }),
    [CANCEL_QUERY]: {
      next: (state, { payload }) => ({ ...state, isRunning: false }),
    },
    [QUERY_COMPLETED]: {
      next: (state, { payload }) => ({
        ...state,
        isRunning: false,
      }),
    },
    [QUERY_ERRORED]: {
      next: (state, { payload }) => ({ ...state, isRunning: false }),
    },

    [SHOW_CHART_SETTINGS]: {
      next: (state, { payload }) => ({
        ...state,
        ...UI_CONTROLS_SIDEBAR_DEFAULTS,
        isShowingChartSettingsSidebar: true,
        initialChartSetting: payload,
      }),
    },
    // AGGREGATION
    [onEditSummary]: state => ({
      ...state,
      ...UI_CONTROLS_SIDEBAR_DEFAULTS,
      isShowingSummarySidebar: true,
    }),
    [onCloseSummary]: state => ({
      ...state,
      ...UI_CONTROLS_SIDEBAR_DEFAULTS,
    }),
    [onOpenChartSettings]: (
      state,
      { payload: { initialChartSettings, showSidebarTitle = false } = {} },
    ) => ({
      ...state,
      ...UI_CONTROLS_SIDEBAR_DEFAULTS,
      isShowingChartSettingsSidebar: true,
      initialChartSetting: initialChartSettings,
      showSidebarTitle: showSidebarTitle,
    }),
    [onCloseChartSettings]: state => ({
      ...state,
      ...UI_CONTROLS_SIDEBAR_DEFAULTS,
    }),
    [onOpenChartType]: state => ({
      ...state,
      ...UI_CONTROLS_SIDEBAR_DEFAULTS,
      isShowingChartTypeSidebar: true,
    }),
    [onCloseChartType]: state => ({
      ...state,
      ...UI_CONTROLS_SIDEBAR_DEFAULTS,
    }),
    [onOpenQuestionInfo]: state =>
      setUIControls(state, {
        ...UI_CONTROLS_SIDEBAR_DEFAULTS,
        isShowingQuestionInfoSidebar: true,
        queryBuilderMode: "view",
      }),
    [onCloseQuestionInfo]: state => ({
      ...state,
      isShowingQuestionInfoSidebar: false,
    }),
    [onOpenTimelines]: state => ({
      ...state,
      ...UI_CONTROLS_SIDEBAR_DEFAULTS,
      ...CLOSED_NATIVE_EDITOR_SIDEBARS,
      isShowingTimelineSidebar: true,
    }),
    [onCloseTimelines]: state => ({
      ...state,
      ...UI_CONTROLS_SIDEBAR_DEFAULTS,
    }),
    [onCloseSidebars]: state => ({
      ...state,
      ...UI_CONTROLS_SIDEBAR_DEFAULTS,
    }),
  },
  DEFAULT_UI_CONTROLS,
);

export const loadingControls = handleActions(
  {
    [SET_DOCUMENT_TITLE]: (state, { payload }) => ({
      ...state,
      documentTitle: payload,
    }),
    [SET_SHOW_LOADING_COMPLETE_FAVICON]: (state, { payload }) => ({
      ...state,
      showLoadCompleteFavicon: payload,
    }),
    [SET_DOCUMENT_TITLE_TIMEOUT_ID]: (state, { payload }) => ({
      ...state,
      timeoutId: payload,
    }),
  },
  DEFAULT_LOADING_CONTROLS,
);

export const queryStatus = handleActions(
  {
    [RUN_QUERY]: state => "running",
    [QUERY_COMPLETED]: state => "complete",
    [CANCEL_QUERY]: state => "idle",
  },
  DEFAULT_QUERY_STATUS,
);

export const zoomedRowObjectId = handleActions(
  {
    [INITIALIZE_QB]: {
      next: (state, { payload }) => payload?.objectId ?? null,
    },
    [ZOOM_IN_ROW]: {
      next: (state, { payload }) => payload.objectId,
    },
    [RESET_ROW_ZOOM]: { next: () => null },
    [RESET_QB]: { next: () => null },
  },
  null,
);

// the card that is actively being worked on
export const card = handleActions(
  {
    [RESET_QB]: { next: (state, { payload }) => null },
    [INITIALIZE_QB]: {
      next: (state, { payload }) => (payload ? payload.card : null),
    },
    [SOFT_RELOAD_CARD]: { next: (state, { payload }) => payload },
    [RELOAD_CARD]: { next: (state, { payload }) => payload },
    [SET_CARD_AND_RUN]: { next: (state, { payload }) => payload.card },
    [API_CREATE_QUESTION]: { next: (state, { payload }) => payload },
    [API_UPDATE_QUESTION]: { next: (state, { payload }) => payload },

    [CANCEL_DATASET_CHANGES]: { next: (state, { payload }) => payload.card },

    [UPDATE_QUESTION]: (state, { payload: { card } }) => card,

    [QUERY_COMPLETED]: {
      next: (state, { payload: { card } }) => ({
        ...state,
        display: card.display,
        result_metadata: card.result_metadata,
        visualization_settings: card.visualization_settings,
      }),
    },

    [CREATE_PUBLIC_LINK]: {
      next: (state, { payload }) => ({ ...state, public_uuid: payload.uuid }),
    },
    [DELETE_PUBLIC_LINK]: {
      next: (state, { payload }) => ({ ...state, public_uuid: null }),
    },
    [UPDATE_ENABLE_EMBEDDING]: {
      next: (state, { payload }) => ({
        ...state,
        enable_embedding: payload.enable_embedding,
      }),
    },
    [UPDATE_EMBEDDING_PARAMS]: {
      next: (state, { payload }) => ({
        ...state,
        embedding_params: payload.embedding_params,
        initially_published_at: payload.initially_published_at,
      }),
    },
  },
  null,
);

// a copy of the card being worked on at it's last known saved state.  if the card is NEW then this should be null.
// NOTE: we use JSON serialization/deserialization to ensure a deep clone of the object which is required
//       because we can't have any links between the active card being modified and the "originalCard" for testing dirtiness
// ALSO: we consistently check for payload.id because an unsaved card has no "originalCard"
export const originalCard = handleActions(
  {
    [INITIALIZE_QB]: {
      next: (state, { payload }) =>
        payload.originalCard ? copy(payload.originalCard) : null,
    },
    [RELOAD_CARD]: {
      next: (state, { payload }) => (payload.id ? copy(payload) : null),
    },
    [SET_CARD_AND_RUN]: {
      next: (state, { payload }) =>
        payload.originalCard ? copy(payload.originalCard) : null,
    },
    [API_CREATE_QUESTION]: {
      next: (state, { payload }) => copy(payload),
    },
    [API_UPDATE_QUESTION]: {
      next: (state, { payload }) => copy(payload),
    },
  },
  null,
);

// references to FK tables specifically used on the ObjectDetail page.
export const tableForeignKeyReferences = handleActions(
  {
    [LOAD_OBJECT_DETAIL_FK_REFERENCES]: {
      next: (state, { payload }) => payload,
    },
    [CLEAR_OBJECT_DETAIL_FK_REFERENCES]: () => null,
  },
  null,
);

export const lastRunCard = handleActions(
  {
    [RESET_QB]: { next: (state, { payload }) => null },
    [QUERY_COMPLETED]: { next: (state, { payload }) => payload.card },
    [QUERY_ERRORED]: { next: (state, { payload }) => null },
  },
  null,
);

// The results of a query execution.  optionally an error if the query fails to complete successfully.
export const queryResults = handleActions(
  {
    [RESET_QB]: { next: (state, { payload }) => null },
    [QUERY_COMPLETED]: {
      next: (state, { payload: { queryResults } }) => queryResults,
    },
    [QUERY_ERRORED]: {
      next: (state, { payload }) => (payload ? [payload] : state),
    },
    [CLEAR_QUERY_RESULT]: { next: (state, { payload }) => null },
  },
  null,
);

export const metadataDiff = handleActions(
  {
    [RESET_QB]: { next: () => ({}) },
    [API_CREATE_QUESTION]: { next: () => ({}) },
    [API_UPDATE_QUESTION]: { next: () => ({}) },
    [SET_METADATA_DIFF]: {
      next: (state, { payload }) => {
        const { name, changes } = payload;
        return {
          ...state,
          [name]: state[name] ? merge(state[name], changes) : changes,
        };
      },
    },
    [CANCEL_DATASET_CHANGES]: { next: () => ({}) },
  },
  {},
);

// promise used for tracking a query execution in progress.  when a query is started we capture this.
export const cancelQueryDeferred = handleActions(
  {
    [RUN_QUERY]: {
      next: (state, { payload: { cancelQueryDeferred } }) =>
        cancelQueryDeferred,
    },
    [CANCEL_QUERY]: { next: (state, { payload }) => null },
    [QUERY_COMPLETED]: { next: (state, { payload }) => null },
    [QUERY_ERRORED]: { next: (state, { payload }) => null },
  },
  null,
);

export const queryStartTime = handleActions(
  {
    [RUN_QUERY]: { next: (state, { payload }) => performance.now() },
    [CANCEL_QUERY]: { next: (state, { payload }) => null },
    [QUERY_COMPLETED]: { next: (state, { payload }) => null },
    [QUERY_ERRORED]: { next: (state, { payload }) => null },
  },
  null,
);

export const parameterValues = handleActions(
  {
    [INITIALIZE_QB]: {
      next: (state, { payload: { parameterValues } }) => parameterValues,
    },
    [SET_PARAMETER_VALUE]: {
      next: (state, { payload: { id, value } }) => assoc(state, id, value),
    },
  },
  {},
);

export const currentState = handleActions(
  {
    [SET_CURRENT_STATE]: { next: (state, { payload }) => payload },
  },
  null,
);

export const parentDashboard = handleActions(
  {
    [NAVIGATE_TO_NEW_CARD]: {
      next: (state, { payload: { dashboardId } }) => ({
        dashboardId,
        isEditing: false,
      }),
    },
    [EDIT_QUESTION]: {
      next: (state, { payload: { dashboardId } }) => ({
        dashboardId,
        isEditing: true,
      }),
    },
    [CLOSE_QB]: { next: () => DEFAULT_DASHBOARD_STATE },
  },
  DEFAULT_DASHBOARD_STATE,
);

export const visibleTimelineEventIds = handleActions(
  {
    [INITIALIZE_QB]: { next: () => [] },
    [SHOW_TIMELINE_EVENTS]: {
      next: (state, { payload: events }) =>
        _.uniq([...state, ...events.map(event => event.id)]),
    },
    [HIDE_TIMELINE_EVENTS]: {
      next: (state, { payload: events }) => {
        const eventIdsToHide = events.map(event => event.id);
        return state.filter(eventId => !eventIdsToHide.includes(eventId));
      },
    },
    [TimelineEvents.actionTypes.CREATE]: {
      next: (state, { payload }) => [...state, payload.timelineEvent.id],
    },
    [RESET_QB]: { next: () => [] },
  },
  [],
);

export const selectedTimelineEventIds = handleActions(
  {
    [INITIALIZE_QB]: { next: () => [] },
    [SELECT_TIMELINE_EVENTS]: {
      next: (state, { payload: events = [] }) => events.map(e => e.id),
    },
    [DESELECT_TIMELINE_EVENTS]: {
      next: () => [],
    },
    [onCloseTimelines]: { next: () => [] },
    [RESET_QB]: { next: () => [] },
  },
  [],
);

export const queryEditorDashboard = handleActions(
  {
    [RESET_DASHBOARD_DATA]: {
      next: (state, { payload }) => DEFAULT_EVENTS_STATE,
    },
    [CLEAR_GLOBAL_GROUP]: {
      next: (state, { payload }) => ({
        ...state,
        currentGroup: {
          properties: [],
          frequentlyUsedProperties: [],
        },
      }),
    },
    [SET_DASHBOARD_MODE]: {
      next: (state, { payload }) => ({
        ...state,
        dashboardMode: payload,
      }),
    },
    [RESET_MEASURED]: {
      next: (state, { payload }) => ({
        ...state,
        measured: {
          completed: {
            amount: 1,
            type: "days",
            value: 86400,
            overtime: "week",
          },
          option: {
            value: "Conversion",
            path: "/v2/datasets/funnel/conversion",
          },
        },
        segmentationFormula: "",
        segmentationMeasured: {
          option: {
            value: "Uniques",
            path: "/v2/datasets/segmentation/standard",
          },
          overtime: "day",
        },
      }),
    },
    [SET_EVENTS]: {
      next: (state, { payload }) => ({
        ...state,
        eventsList: payload,
      }),
    },
    [SET_GLOBAL_FILTERS]: {
      next: (state, { payload }) => ({
        ...state,
        globalGroup: {
          ...state.globalGroup,
          filters: payload,
        },
      }),
    },
    [SET_GLOBAL_FREQUENTLY_FILTERS]: {
      next: (state, { payload }) => ({
        ...state,
        globalGroup: {
          ...state.globalGroup,
          frequentlyFilters: payload,
        },
      }),
    },
    [SET_COHORTS_VALUES]: {
      next: (state, { payload }) => ({
        ...state,
        cohortsValues: payload,
      }),
    },
    [SET_SEGMENT_PROPERTIES]: {
      next: (state, { payload }) => ({
        ...state,
        segmentProperties: payload,
      }),
    },
    [SET_EVENT_GROUP_PROPERTIES]: {
      next: (state, { payload }) => ({
        ...state,
        eventGroupProperties: payload,
      }),
    },
    [SET_EVENT_GROUP_FREQUENTLY_USED]: {
      next: (state, { payload }) => ({
        ...state,
        eventGroupFrequentlyUsed: payload,
      }),
    },
    [SET_EVENTS_PROPERTIES_VALUES]: {
      next: (state, { payload }) => ({
        ...state,
        eventsPropertiesValues: payload,
      }),
    },
    [SET_SEGMENT_VALUES]: {
      next: (state, { payload }) => ({
        ...state,
        segmentValues: payload,
      }),
    },
    [SET_CURRENT_EVENTS_ORDER]: {
      next: (state, { payload }) => ({
        ...state,
        eventsOrder: payload,
      }),
    },
    [ADD_EVENT]: {
      next: (state, { payload }) => ({
        ...state,
        currentEvents: [...state.currentEvents, payload],
      }),
    },
    [ADD_SEGMENT]: {
      next: (state, { payload }) => ({
        ...state,
        currentSegments: [...state.currentSegments, payload],
      }),
    },
    [UPDATE_CURRENT_GROUP]: {
      next: (state, { payload }) => ({
        ...state,
        currentGroup: payload,
      }),
    },
    [TOGGLE_IS_FIRST_DASHBOARD_INITIALIZATION]: {
      next: (state, { payload }) => ({
        ...state,
        isFirstDashboardInitializaton: payload,
      }),
    },
    [CLEAR_EVENT_GROUP]: {
      next: (state, { payload }) => ({
        ...state,
        currentEvents: state.currentEvents.map(event => {
          if (event.cardUniqueId === payload.eventId) {
            return {
              ...event,
              groupList: payload.groupData,
            };
          }
          return event;
        }),
      }),
    },
    [SET_EVENT_GROUP]: {
      next: (state, { payload }) => ({
        ...state,
        currentEvents: state.currentEvents.map(event => {
          if (event.cardUniqueId === payload.eventId) {
            return {
              ...event,
              groupList: payload.groupList,
            };
          }
          return event;
        }),
      }),
    },
    [REMOVE_EVENT]: {
      next: (state, { payload }) => ({
        ...state,
        currentEvents: state.currentEvents.filter(
          event => event.cardUniqueId !== payload,
        ),
      }),
    },
    [REMOVE_SEGMENT]: {
      next: (state, { payload }) => ({
        ...state,
        currentSegments: state.currentSegments.filter(
          event => event.id !== payload,
        ),
      }),
    },
    [REMOVE_EVENT_PROPERTY]: {
      next: (state, { payload }) => ({
        ...state,
        currentEvents: state.currentEvents.map(event => {
          if (event.cardUniqueId === payload.eventId) {
            return {
              ...event,
              properties: event.properties.filter(
                property => property.id !== payload.propertyId,
              ),
            };
          }
          return event;
        }),
      }),
    },
    [SET_CURRENT_PROPERTIES]: {
      next: (state, { payload }) => ({
        ...state,
        currentEvents: state.currentEvents.map(event => {
          if (event.id === payload.eventId) {
            return {
              ...event,
              propertiesList: payload.properties,
            };
          }
          return event;
        }),
      }),
    },
    [SET_CURRENT_EVENT_FILTERS]: {
      next: (state, { payload }) => ({
        ...state,
        currentEvents: state.currentEvents.map(event => {
          if (event.cardUniqueId === payload.eventId) {
            return {
              ...event,
              filtersList: payload.filters,
            };
          }
          return event;
        }),
      }),
    },
    [SET_CURRENT_FILTERS]: {
      next: (state, { payload }) => ({
        ...state,
        currentEvents: state.currentEvents.map(event => {
          if (event.cardUniqueId === payload.eventId) {
            return {
              ...event,
              groupFiltersList: payload.filters,
            };
          }
          return event;
        }),
      }),
    },
    [SET_CURRENT_FREQUENTLY_FILTERS]: {
      next: (state, { payload }) => ({
        ...state,
        currentEvents: state.currentEvents.map(event => {
          if (event.cardUniqueId === payload.eventId) {
            return {
              ...event,
              groupFrequentlyFiltersList: payload.filters,
            };
          }
          return event;
        }),
      }),
    },
    [SET_CURRENT_GROUP_PROPERTIES]: {
      next: (state, { payload }) => ({
        ...state,
        currentEvents: state.currentEvents.map(event => {
          if (event.id === payload.eventId) {
            return {
              ...event,
              groupProperties: payload.properties,
            };
          }
          return event;
        }),
      }),
    },
    [SET_CURRENT_GROUP_FREQUENTLY_PROPERTIES]: {
      next: (state, { payload }) => ({
        ...state,
        currentEvents: state.currentEvents.map(event => {
          if (event.id === payload.eventId) {
            return {
              ...event,
              groupFrequentlyProperties: payload.properties,
            };
          }
          return event;
        }),
      }),
    },
    [REMOVE_SEGMENT_COHORT]: {
      next: (state, { payload }) => ({
        ...state,
        currentSegments: state.currentSegments.map(event => {
          if (event.id === payload.eventId) {
            return {
              ...event,
              cohorts: event.cohorts.filter(
                property => property.id !== payload.propertyId,
              ),
            };
          }
          return event;
        }),
      }),
    },
    [REMOVE_SEGMENT_FILTER]: {
      next: (state, { payload }) => ({
        ...state,
        currentSegments: state.currentSegments.map(event => {
          if (event.id === payload.eventId) {
            return {
              ...event,
              filters: event.filters.filter(
                property => property.id !== payload.propertyId,
              ),
            };
          }
          return event;
        }),
      }),
    },
    [UPDATE_EVENT_PROPERTIES]: {
      next: (state, { payload }) => ({
        ...state,
        currentEvents: state.currentEvents.map(event => {
          if (event.cardUniqueId === payload.cardId) {
            return {
              ...event,
              properties: payload.properties,
            };
          }
          return event;
        }),
      }),
    },
    [UPDATE_PROPERTY_CONDITION]: {
      next: (state, { payload }) => ({
        ...state,
        currentEvents: state.currentEvents.map(event => {
          if (event.cardUniqueId === payload.cardUniqueId) {
            return {
              ...event,
              properties: event.properties.map(property => {
                if (property.id === payload.propertyId) {
                  return {
                    ...property,
                    condition: payload.propertyCondition,
                    selected: payload.selectedOptions,
                    values: payload.selectedProperties,
                  };
                }
                return property;
              }),
            };
          }
          return event;
        }),
      }),
    },
    [UPDATE_SEGMENT_PROPERTY_CONDITION]: {
      next: (state, { payload }) => ({
        ...state,
        currentSegments: state.currentSegments.map(event => {
          if (event.id === payload.cardId) {
            return {
              ...event,
              filters: event.filters.map(filter => {
                if (filter.id === payload.propertyId) {
                  return {
                    ...filter,
                    condition: payload.propertyCondition,
                    selected: payload.selectedOptions,
                    values: payload.selectedProperties,
                  };
                }
                return filter;
              }),
            };
          }
          return event;
        }),
      }),
    },
    [UPDATE_SEGMENT_CONDITION]: {
      next: (state, { payload }) => ({
        ...state,
        currentSegments: state.currentSegments.map(event => {
          if (event.id === payload.cardId) {
            return {
              ...event,
              cohorts: event.cohorts.map(cohort => {
                if (cohort.id === payload.propertyId) {
                  return {
                    ...cohort,
                    condition: payload.propertyCondition,
                    selected: payload.selectedOptions,
                    values: payload.selectedProperties,
                  };
                }
                return cohort;
              }),
            };
          }
          return event;
        }),
      }),
    },
    [UPDATE_PROPERTY_INFO]: {
      next: (state, { payload }) => ({
        ...state,
        currentEvents: state.currentEvents.map(event => {
          if (event.id === payload.eventId) {
            return {
              ...event,
              properties: event.properties.map(property => {
                if (property.id === payload.propertyId) {
                  return {
                    ...property,
                    values: payload.values,
                    condition: payload.condition,
                    selected: payload.selected,
                  };
                }
              }),
            };
          }
          return event;
        }),
      }),
    },
    [UPDATE_SEGMENT_COHORTS]: {
      next: (state, { payload }) => ({
        ...state,
        currentSegments: state.currentSegments.map(event => {
          if (event.id === payload.cardId) {
            return {
              ...event,
              cohorts: [...event.cohorts, payload.newCohort],
            };
          }
          return event;
        }),
      }),
    },
    [UPDATE_SEGMENT_FILTERS]: {
      next: (state, { payload }) => ({
        ...state,
        currentSegments: state.currentSegments.map(event => {
          if (event.id === payload.cardId) {
            return {
              ...event,
              filters: [...payload.properties],
            };
          }
          return event;
        }),
      }),
    },
    [UPDATE_EVENTS_CARDS]: {
      next: (state, { payload }) => ({
        ...state,
        currentEvents: payload,
      }),
    },
    [UPDATE_SEGMENTS_CARDS]: {
      next: (state, { payload }) => ({
        ...state,
        currentSegments: payload,
      }),
    },
    [SET_EVENTS_PROPERTIES]: {
      next: (state, { payload }) => ({ ...state, currentProperties: payload }),
    },
    [SET_MEASURED_COMPLETED]: {
      next: (state, { payload }) => ({
        ...state,
        measured: {
          ...state.measured,
          completed: payload,
        },
      }),
    },
    [SET_MEASURED_OVERTIME]: {
      next: (state, { payload }) => ({
        ...state,
        measured: {
          ...state.measured,
          completed: {
            ...state.measured.completed,
            overtime: payload,
          },
        },
      }),
    },
    [SET_SEGMENTATION_MEASURED_OVERTIME]: {
      next: (state, { payload }) => ({
        ...state,
        segmentationMeasured: {
          ...state.segmentationMeasured,
          overtime: payload,
        },
      }),
    },
    [SET_SIGNIFICANCE_LEVEL_DATA]: {
      next: (state, { payload }) => ({
        ...state,
        significance: payload,
      }),
    },
    [SET_BASELINE_SEGMENT]: {
      next: (state, { payload }) => ({
        ...state,
        baseline: payload,
      }),
    },
    [SET_MEASURED_OPTION]: {
      next: (state, { payload }) => ({
        ...state,
        measured: {
          ...state.measured,
          option: payload,
        },
      }),
    },
    [SET_SEGMENTATION_MEASURED_OPTION]: {
      next: (state, { payload }) => ({
        ...state,
        segmentationMeasured: {
          ...state.segmentationMeasured,
          option: payload,
        },
      }),
    },
    [GET_EVENTS_PROPERTIES]: {
      next: (state, { payload }) => ({ ...state, properties: payload }),
    },
    [SET_SEGMENTATION_FORMULA]: {
      next: (state, { payload }) => ({
        ...state,
        segmentationFormula: payload,
      }),
    },
    [GET_EVENTS_LIST]: {
      next: (state, { payload }) => ({ ...state, events: payload }),
    },
    [RESORE_DASHBOARD_DATA]: {
      next: (state, { payload }) => ({
        ...state,
        measured: {
          ...state.measured,
          completed: {
            amount: payload.measured.amount,
            type: payload.measured.type,
            value: payload.measured.value,
            overtime: payload.measured.overtime,
          },
          option: {
            ...state.measured.option,
            path: payload.measured.path,
          },
        },
        currentGroup: {
          // ...state.currentGroup,
          properties: payload.currentGroup.map(group => {
            return {
              name: group.prop_name,
              subname: group.prop_subname,
            };
          }),
          frequentlyUsedProperties: [],
        },
        dashboardMode: payload.dashboardMode,
        eventsOrder: payload.eventsOrder,
        baseline: payload.baseline,
        siginificance: payload.siginificance,
        currentSegments: payload.segments.map(segment => {
          const cohortsList = segment.conditions.filter(condition => {
            return condition.prop_name === "userdata_cohort";
          });

          const filtersList = segment.conditions.filter(condition => {
            return condition.prop_name !== "userdata_cohort";
          });

          return {
            id: segment.name,
            value: segment.name,
            cohorts: cohortsList.map(cohort => {
              return {
                id: segment.name,
                cohortsList: cohort.prop_value?.map(cohortValue => {
                  return {
                    id: cohortValue,
                    display: cohortValue,
                    load_date: "",
                  };
                }),
              };
            }),
            filters: filtersList.map(filter => {
              return {
                id: "",
                name: filter.prop_name,
                subname: filter.sub_name,
                type: "string",
                display: "",
                condition: filter.prop_op,
                selected: [],
                values: filter.prop_value?.map(value => {
                  return {
                    id: value,
                    display: value,
                  };
                }),
              };
            }),
          };
        }),
        currentEvents: payload.events.map(item => {
          return {
            id: item.event_type,
            display: item.event_type,
            value: item.event_type,
            cardUniqueId: item.event_type,
            properties: item.filters.map(filter => {
              return {
                id: "",
                name: filter.prop_name,
                subname: filter.sub_name,
                type: "string",
                display: "",
                condition: filter.prop_op,
                selected: [],
                values: filter.prop_value?.map(value => {
                  return {
                    id: value,
                    display: value,
                  };
                }),
              };
            }),
            groupList: {
              properties: item.group_by?.map(group => {
                return {
                  id: "",
                  name: group.prop_name,
                  subname: group.prop_subname,
                  type: "string",
                  display: group.prop_name,
                };
              }),
              frequentlyUsedProperties: [],
            },
          };
        }),
      }),
    },
  },
  DEFAULT_EVENTS_STATE,
);
