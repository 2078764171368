import { useEffect, useState } from "react";

import CheckBox from "metabase/core/components/CheckBox";
import { color } from "metabase/lib/colors";

import type { ICohortItem, ITaskData } from "../../types/interfaces";

import {
  CheckboxContainer,
  CheckboxesList,
  SelectAllContainer,
} from "./ModalCheckboxes.styled";

interface IModalCheckboxesProps {
  onCheckboxChange: (values: ICohortItem[]) => void;
  values?: ITaskData[];
  currentProperties?: [];
  isOpen?: boolean;
}

export const ModalCheckboxes = ({
  onCheckboxChange,
  values,
  currentProperties,
  isOpen,
}: IModalCheckboxesProps) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (value: any) => {
    setSelectedOptions(prevSelected => {
      const idList = prevSelected.map(item => item?.id);

      if (idList.includes(value.id)) {
        return prevSelected.filter(item => item.id !== value?.id);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  const handleSelectAllChange = e => {
    const isChecked = e.target.checked;

    if (isChecked) {
      setSelectedOptions(values);
    } else {
      setSelectedOptions([]);
    }

    onCheckboxChange(selectedOptions);
  };

  useEffect(() => {
    onCheckboxChange(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (isOpen && currentProperties) {
      setSelectedOptions(currentProperties);
    }
  }, [isOpen, currentProperties]);

  return (
    <>
      <SelectAllContainer>
        <CheckBox
          checked={selectedOptions?.length === values?.length}
          onChange={handleSelectAllChange}
          aria-label={`Select all items`}
          label={"Select all"}
          uncheckedColor={color("border")}
        />
      </SelectAllContainer>
      <CheckboxesList>
        {values?.map((item, index) => {
          return (
            <CheckboxContainer key={index}>
              <CheckBox
                name={item.display}
                checked={selectedOptions.find(option => option?.id === item.id)}
                onChange={() => handleChange(item)}
                aria-label={item.display}
                label={item.display}
                uncheckedColor={color("border")}
              />
            </CheckboxContainer>
          );
        })}
      </CheckboxesList>
    </>
  );
};
