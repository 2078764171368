import styled from "@emotion/styled";

import Button from "metabase/core/components/Button";
import { color } from "metabase/lib/colors";
import { TextInput, Title } from "metabase/ui";

interface IStyledButtonProps {
  isActive?: boolean;
}

export const TabsContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledTitle = styled(Title)`
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 900;
  margin-bottom: 16px;
`;

export const StyledButton = styled(Button)<IStyledButtonProps>`
  min-width: 146px;
  background-color: ${props =>
    props.isActive ? color("brand") : color("white")};
  border-color: ${props => (props.isActive ? color("brand") : color("border"))};
  color: ${props => (props.isActive ? color("white") : color("text-dark"))};
`;

export const StyledMeasuredLink = styled(Button)`
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 700;
  border: none;
  color: ${color("brand")};
`;

export const StyledApplyButton = styled(Button)`
  min-width: 104px;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  color: ${color("white")};
  background-color: ${color("brand")};
  border-color: ${color("brand")};
`;

export const StyledCloseButton = styled(Button)`
  min-width: 104px;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1.5rem;
  color: ${color("text-dark")};
  background-color: ${color("white")};
  border-color: ${color("white")};
`;

export const FormulaTextInput = styled(TextInput)`
  padding: 1rem 0;
  margin-right: 0;

  input {
    border-radius: 6px;
    background-color: ${color("white")};
  }
`;

export const MeasuredFormulaContainer = styled.div`
  width: 45.8%;
`;

export const MeasuredControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;
