import { useContext, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "metabase/lib/redux";
import {
  addEvent,
  fetchEvents,
  removeEvent,
  removeProperty,
  setCurrentEventsOrder,
  setEvents,
  updateEventsCards,
} from "metabase/query_builder/actions";
import {
  getCurrentEvents,
  getCurrentEventsOrder,
  getDashboardMode,
  getEventsList,
  getPropertyValues,
} from "metabase/query_builder/selectors";
import type { NativeDatasetQuery } from "metabase-types/api";

import { usePostEventsNativeQueryMutation } from "../api/dashboardApi";
import { AddTaskModal } from "../components/AddTaskModal";
import { GroupButton } from "../components/GroupButton/GroupButton";
import { GroupCards } from "../components/GroupCards";
import { GroupContainer } from "../components/GroupContainer";
import { GroupLink } from "../components/GroupLink";
import { GroupSelect } from "../components/GroupSelect";
import { CardsContext } from "../contexts/CardsContext";
import { DashboardMode } from "../types/enum";
import type {
  IColsItem,
  IEventProperty,
  IEventsEndpoints,
  IValueItem,
} from "../types/interfaces";
import { mapApiData } from "../utils/mapApiData";

import { EventsHeaderWrapper, StyledTitle } from "./EventsSection.styled";

interface ICardItem {
  id: string;
  display: string;
  value: string;
  properties: IEventProperty[];
}

interface IEventsSectionProps {
  updateQuery?: any;
  getQueryText: () => string;
  databaseId: number;
  endpoints: IEventsEndpoints;
}

export const EventsSection = ({ endpoints }: IEventsSectionProps) => {
  const [postNativeQuery] = usePostEventsNativeQueryMutation();

  const dispatch = useDispatch();
  const dashboardMode = useSelector(getDashboardMode);
  const eventsList = useSelector(getEventsList);
  const currentEvents = useSelector(getCurrentEvents);
  const currentEventsOrder = useSelector(getCurrentEventsOrder);
  const propertyValues = useSelector(getPropertyValues);
  const EventsContext = useContext(CardsContext);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const eventOrderOptions = [
    {
      id: 1,
      value: "In this order",
      label: "In this order",
      valueId: "this_order",
    },
    {
      id: 2,
      value: "In any order",
      label: "In any order",
      valueId: "any_order",
    },
    {
      id: 3,
      value: "In exact order",
      label: "In exact order",
      valueId: "exact_order",
    },
  ];

  const idCounter = useRef(1);

  const generateId = (): number => {
    const id = idCounter.current++;
    return id;
  };

  const fetchFiltersData = async () => {
    const payloadQuery = `{
      "path": "${endpoints.events}",
      "method": "get",
      "body": {},
      "headers": {}
    }`;
    const nativeQuery: NativeDatasetQuery = {
      native: {
        query: payloadQuery,
      },
      type: "native",
      database: 2,
    };
    try {
      const response = await postNativeQuery(nativeQuery).unwrap();
      const formattedKeys = response?.data?.cols?.map(
        (col: IColsItem) => col.name,
      );
      const formattedValues = response?.data?.rows;
      const eventsData = mapApiData(formattedKeys, formattedValues);

      dispatch(setEvents(eventsData));
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  useEffect(() => {
    fetchFiltersData();
  }, []);

  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  const onTaskModalClose = () => {
    setIsTaskModalOpen(false);
  };

  const onTaskModalOpen = () => {
    setIsTaskModalOpen(true);
  };

  const addEventCard = (cardId: string) => {
    const eventCard = eventsList?.find(
      (card: IValueItem) => card.id === cardId,
    );
    const uniqueId = generateId();
    const newCard = {
      id: uniqueId,
      display: eventCard?.display,
      value: eventCard?.display,
      properties: [],
      cardUniqueId: uniqueId,
      groupList: {
        properties: [],
        frequentlyUsedProperties: [],
      },
    };

    dispatch(addEvent(newCard));
  };

  const removeEventCard = (cardId: string) => {
    dispatch(removeEvent(cardId));
  };

  const removeEventProperty = (cardId: string, propertyId: string) => {
    dispatch(removeProperty(cardId, propertyId));
  };

  const updateCards = (cardsList: ICardItem[]) => {
    dispatch(updateEventsCards(cardsList));
  };

  return (
    <GroupContainer>
      <>
        <EventsHeaderWrapper>
          <StyledTitle>{"Events"}</StyledTitle>
          {dashboardMode === DashboardMode.Funnel && (
            <GroupSelect
              value={currentEventsOrder.label}
              data={eventOrderOptions}
              onChange={value => {
                const currentOrder = eventOrderOptions.find(
                  item => item.label === value,
                );
                dispatch(setCurrentEventsOrder(currentOrder));
              }}
            />
          )}
        </EventsHeaderWrapper>
        <CardsContext.Provider value={{ ...EventsContext }}>
          <GroupCards
            onDelete={removeEventCard}
            cardsList={currentEvents}
            onPropertyDelete={removeEventProperty}
            updateCards={updateCards}
            propertyValues={propertyValues}
            endpoints={endpoints}
          />
        </CardsContext.Provider>
        {currentEvents.length ? (
          <GroupLink name={"Add events"} onClick={onTaskModalOpen} />
        ) : (
          <GroupButton name={"Add events"} onClick={onTaskModalOpen} />
        )}
        <AddTaskModal
          title={"Add Events"}
          data={eventsList}
          isOpen={isTaskModalOpen}
          onClose={onTaskModalClose}
          onSave={addEventCard}
        />
      </>
    </GroupContainer>
  );
};
