import { createAction } from "redux-actions";

import { createThunkAction } from "metabase/lib/redux";
import type {
  Dispatch,
  GetState,
  QueryBuilderUIControls,
} from "metabase-types/store";
import { create } from "underscore";

export const GET_EVENTS_LIST = "metabase/qb/GET_EVENTS_LIST";
export const fetchEvents = createThunkAction(GET_EVENTS_LIST, () => {
  return async dispatch => {
    const action = await dispatch([
      {
        id: "1",
        display: "Catalog - screen view",
        value: "Catalog - screen view",
      },
      {
        id: "2",
        display: "content_type",
        value: "content_type",
      },
    ]);

    return action;
  };
});

export const GET_EVENTS_PROPERTIES = "metabase/qb/GET_EVENTS_PROPERTIES";

export const fetchEventProperties = createThunkAction(
  GET_EVENTS_PROPERTIES,
  () => {
    return async dispatch => {
      const action = await dispatch([
        {
          id: "1",
          type: "string",
          value: "categoryTitle",
          display: "categoryTitle",
        },
        {
          id: "2",
          type: "empty",
          value: "categoryTitle",
          display: "categoryTitle",
        },
      ]);

      return action;
    };
  },
);

export const TOGGLE_IS_FIRST_DASHBOARD_INITIALIZATION =
  "metabase/qb/TOGGLE_IS_FIRST_DASHBOARD_INITIALIZATION";

export const toggleIsFirstDashboardInitializaton = createAction(
  TOGGLE_IS_FIRST_DASHBOARD_INITIALIZATION,
);

export const ADD_EVENT = "metabase/qb/ADD_EVENT";

export const addEvent = createAction(ADD_EVENT);

export const REMOVE_EVENT = "metabase/qb/REMOVE_EVENT";

export const removeEvent = createAction(REMOVE_EVENT);

export const SET_DASHBOARD_MODE = "metabase/qb/SET_DASHBOARD_MODE";

export const setDashboardMode = createAction(SET_DASHBOARD_MODE);

export const ADD_SEGMENT = "metabase/qb/ADD_SEGMENT";

export const addSegment = createAction(ADD_SEGMENT);

export const REMOVE_SEGMENT = "metabase/qb/REMOVE_SEGMENT";

export const removeSegment = createAction(REMOVE_SEGMENT);

export const RESORE_DASHBOARD_DATA = "metabase/qb/RESORE_DASHBOARD_DATA";

export const restoreDashboardData = createAction(RESORE_DASHBOARD_DATA);

export const UPDATE_PROPERTY_CONDITION =
  "metabase/qb/UPDATE_PROPERTY_CONDITION";

export const updatePropertyCondition = createAction(UPDATE_PROPERTY_CONDITION);

export const UPDATE_SEGMENT_PROPERTY_CONDITION =
  "metabase/qb/UPDATE_SEGMENT_PROPERTY_CONDITION";

export const updateSegmentPropertyCondition = createAction(
  UPDATE_SEGMENT_PROPERTY_CONDITION,
);

export const CLEAR_GLOBAL_GROUP = "metabase/qb/CLEAR_GLOBAL_GROUP";

export const clearGlobalGroup = createAction(CLEAR_GLOBAL_GROUP);

export const SET_MEASURED_COMPLETED = "metabase/qb/SET_MEASURED_COMPLETED";

export const setMeasuredCompleted = createAction(SET_MEASURED_COMPLETED);

export const SET_MEASURED_OVERTIME = "metabase/qb/SET_MEASURED_OVERTIME";

export const setMeasuredOvertime = createAction(SET_MEASURED_OVERTIME);

export const SET_SEGMENTATION_MEASURED_OVERTIME =
  "metabase/qb/SET_SEGMENTATION_MEASURED_OVERTIME";

export const setSegmentationMeasuredOvertime = createAction(
  SET_SEGMENTATION_MEASURED_OVERTIME,
);

export const SET_SIGNIFICANCE_LEVEL_DATA =
  "metabase/qb/SET_SIGNIFICANCE_LEVEL_DATA";

export const setSignificanceLevelData = createAction(
  SET_SIGNIFICANCE_LEVEL_DATA,
);

export const SET_BASELINE_SEGMENT = "metabase/qb/SET_BASELINE_SEGMENT";

export const setBaselineSegment = createAction(SET_BASELINE_SEGMENT);

export const SET_MEASURED_OPTION = "metabase/qb/SET_MEASURED_OPTION";

export const setMeasuredOption = createAction(SET_MEASURED_OPTION);

export const SET_SEGMENTATION_MEASURED_OPTION =
  "metabase/qb/SET_SEGMENTATION_MEASURED_OPTION";

export const setSegmentationMeasuredOption = createAction(
  SET_SEGMENTATION_MEASURED_OPTION,
);

export const RESET_DASHBOARD_DATA = "metabase/qb/RESET_DASHBOARD_DATA";

export const resetDashboardData = createAction(RESET_DASHBOARD_DATA);

export const UPDATE_EVENTS_CARDS = "metabase/qb/UPDATE_EVENTS_CARDS";

export const updateEventsCards = createAction(UPDATE_EVENTS_CARDS);

export const UPDATE_EVENT_PROPERTIES = "metabase/qb/UPDATE_EVENT_PROPERTIES";

export const updateEventProperties = createAction(UPDATE_EVENT_PROPERTIES);

export const UPDATE_SEGMENT_COHORTS = "metabase/qb/UPDATE_SEGMENT_COHORTS";

export const updateSegmentCohorts = createAction(UPDATE_SEGMENT_COHORTS);

export const UPDATE_SEGMENT_FILTERS = "metabase/qb/UPDATE_SEGMENT_FILTERS";

export const updateSegmentFilters = createAction(UPDATE_SEGMENT_FILTERS);

export const UPDATE_CURRENT_GROUP = "metabase/qb/UPDATE_CURRENT_GROUP";

export const updateCurrentGroup = createAction(UPDATE_CURRENT_GROUP);

export const CLEAR_EVENT_GROUP = "metabase/qb/CLEAR_EVENT_GROUP";

export const clearEventGroup = createAction(CLEAR_EVENT_GROUP);

export const SET_EVENT_GROUP = "metabase/qb/SET_EVENT_GROUP";

export const setEventGroup = createAction(SET_EVENT_GROUP);

export const SET_EVENTS = "metabase/qb/SET_EVENTS";

export const setEvents = createAction(SET_EVENTS);

export const RESET_MEASURED = "metabase/qb/RESET_MEASURED";

export const resetMeasured = createAction(RESET_MEASURED);

export const SET_SEGMENT_PROPERTIES = "metabase/qb/SET_SEGMENT_PROPERTIES";

export const setSegmentProperties = createAction(SET_SEGMENT_PROPERTIES);

export const SET_COHORTS_VALUES = "metabase/qb/SET_COHORTS_VALUES";

export const setCohortsValues = createAction(SET_COHORTS_VALUES);

export const SET_SEGMENTATION_FORMULA = "SET_SEGMENTATION_FORMULA";

export const setSegmentationFormula = createAction(SET_SEGMENTATION_FORMULA);

export const SET_EVENTS_PROPERTIES_VALUES =
  "metabase/qb/SET_EVENTS_PROPERTIES_VALUES";

export const setEventsPropertiesValues = createAction(
  SET_EVENTS_PROPERTIES_VALUES,
);

export const SET_SEGMENT_VALUES = "metabase/qb/SET_SEGMENT_VALUES";

export const setSegmentValues = createAction(SET_SEGMENT_VALUES);

export const SET_CURRENT_EVENT_FILTERS =
  "metabase/qb/SET_CURRENT_EVENT_FILTERS";

export const setCurrentEventFilters = createAction(SET_CURRENT_EVENT_FILTERS);

export const SET_CURRENT_EVENTS_ORDER = "metabase/qb/SET_CURRENT_EVENTS_ORDER";

export const setCurrentEventsOrder = createAction(SET_CURRENT_EVENTS_ORDER);

export const SET_GLOBAL_FILTERS = "metabase/qb/SET_GLOBAL_FILTERS";

export const setGlobalFilters = createAction(SET_GLOBAL_FILTERS);

export const SET_GLOBAL_FREQUENTLY_FILTERS =
  "metabase/qb/SET_GLOBAL_FREQUENTLY_FILTERS";

export const setGlobalFrequentlyFilters = createAction(
  SET_GLOBAL_FREQUENTLY_FILTERS,
);

export const SET_CURRENT_FILTERS = "metabase/qb/SET_CURRENT_FILTERS";

export const setCurrentFilters = createAction(SET_CURRENT_FILTERS);

export const SET_CURRENT_FREQUENTLY_FILTERS =
  "metabase/qb/SET_CURRENT_FREQUENTLY_FILTERS";

export const setCurrentFrequentlyFilters = createAction(
  SET_CURRENT_FREQUENTLY_FILTERS,
);

export const SET_CURRENT_PROPERTIES = "metabase/qb/SET_CURRENT_PROPERTIES";

export const setCurrentProperties = createAction(SET_CURRENT_PROPERTIES);

export const REMOVE_EVENT_PROPERTY = "metabase/qb/REMOVE_EVENT_PROPERTY";

export const removeProperty = (eventId: string, propertyId: string) => ({
  type: REMOVE_EVENT_PROPERTY,
  payload: {
    eventId,
    propertyId,
  },
});

export const REMOVE_SEGMENT_COHORT = "metabase/qb/REMOVE_SEGMENT_COHORT";

export const removeSegmentCohort = (eventId: string, propertyId: string) => ({
  type: REMOVE_SEGMENT_COHORT,
  payload: {
    eventId,
    propertyId,
  },
});

export const REMOVE_SEGMENT_FILTER = "metabase/qb/REMOVE_SEGMENT_FILTER";

export const removeSegmentFilter = (eventId: string, propertyId: string) => ({
  type: REMOVE_SEGMENT_FILTER,
  payload: {
    eventId,
    propertyId,
  },
});
