import styled from "@emotion/styled";

import Button from "metabase/core/components/Button";
import { color } from "metabase/lib/colors";
import { NumberInput, Title } from "metabase/ui";

interface IStyledButtonProps {
  isActive?: boolean;
}

export const TabsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledTitle = styled(Title)`
  font-size: 0.8rem;
  line-height: 1rem;
  font-weight: 900;
  margin-bottom: 16px;
`;

export const StyledButton = styled(Button)<IStyledButtonProps>`
  min-width: 146px;
  background-color: ${props =>
    props.isActive ? color("brand") : color("white")};
  border-color: ${props => (props.isActive ? color("brand") : color("border"))};
  color: ${props => (props.isActive ? color("white") : color("text-dark"))};
`;

export const SignificanceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DashboardTextInput = styled(NumberInput)`
  padding: 0.5rem 0;
  margin-right: 0;
  min-width: 19rem;
  width: 200px;
  margin-bottom: 5px;

  input {
    background-color: ${color("white")};
    border-radius: 6px;
  }
`;

export const MeasuredTitle = styled.h3`
  margin-bottom: 5px;
  font-size: 0.81rem;
  line-height: 0.95rem;
  font-weight: 500;
  color: ${color("text-dark")};
  opacity: 0.8;
  cursor: pointer;
`;

export const SelectButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 302px;
  padding: 3px 8px;
  border: 1px solid #eaf5fb;
  border-radius: 8px;
  color: inherit;
  background-color: ${color("white")};
  cursor: pointer;
  margin-bottom: 1rem;
`;

export const WarningWindow = styled.div`
  width: 615px;
  background-color: #e4eafa;
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 1rem;

  span {
    font-size: 30px;
    color: ${color("warning")};
  }
`;

export const WarningHeader = styled.div`
  margin-bottom: 11px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const WarningTitle = styled.h3`
  margin: 0;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  color: ${color("text-dark")};
`;

export const WarningText = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 15.6px;
  font-weight: 700;
  color: ${color("text-medium")};
  opacity: 0.8;
`;
