import styled from "@emotion/styled";

import { color } from "metabase/lib/colors";

export interface ICardHeaderNumberProps {
  isHover: boolean;
}

export const CardHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1rem 1fr auto;
  gap: 1rem;
  align-items: center;
  padding: 1.1rem 0.75rem;
  border-bottom: 1px solid ${color("border")};
`;

export const CardHeaderNumber = styled.p<ICardHeaderNumberProps>`
  margin: 0;
  font-size: 0.75rem;
  line-height: 0.87rem;
  font-weight: 700;
  color: ${color("text-medium")};
  visibility: ${props => (props.isHover ? "hidden" : "visible")};
`;

export const CardHeaderTitle = styled.h3`
  margin: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: ${color("text-dark")};
`;

export const CardHeaderDelete = styled.button`
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${color("brand")};
`;
