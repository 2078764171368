import type {
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier,
} from "@dnd-kit/core";
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useEffect, useRef, useState } from "react";

import { useSelector } from "metabase/lib/redux";
import { getCurrentSegments } from "metabase/query_builder/selectors";

import type { ISegmentItem, ISegmentsEndpoints } from "../../types/interfaces";
import { SegmentsGroupCard } from "../SegmentsGroupCard/SegmentsGroupCard";

import { GroupCardsList, StyledGroupCards } from "./SegmentsGroupCards.styled";

interface IGroupCardsProps {
  onDelete: (cardId: string) => void;
  endpoints: ISegmentsEndpoints;
}

export const SegmentsGroupCards = ({
  onDelete,
  endpoints,
}: IGroupCardsProps) => {
  const currentSegments = useSelector(getCurrentSegments);
  const [items, setItems] = useState(currentSegments);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const isCardsUpdate = useRef(false);

  useEffect(() => {
    setItems(currentSegments);
  }, [currentSegments]);

  const getItems = (id: UniqueIdentifier): number =>
    items.findIndex((item: ISegmentItem) => item.id === id);

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((items: ISegmentItem[]) => {
        const originalPosition = getItems(active.id);

        const newPosition = getItems(over?.id);

        return arrayMove(items, originalPosition, newPosition);
      });
      isCardsUpdate.current = true;
    }
    setActiveId(null);
  };

  const handleDragStart = (event: DragStartEvent): void => {
    setActiveId(event.active.id);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
    >
      <StyledGroupCards isActive={items.length > 0}>
        <SortableContext items={items} strategy={horizontalListSortingStrategy}>
          <GroupCardsList>
            {items.map((item: ISegmentItem, index: number) => {
              return (
                <SegmentsGroupCard
                  key={item.id}
                  item={item}
                  index={index}
                  onDelete={onDelete}
                  endpoints={endpoints}
                />
              );
            })}
          </GroupCardsList>
        </SortableContext>
      </StyledGroupCards>
      <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
        {activeId ? (
          <SegmentsGroupCard
            key={activeId}
            item={items.find((item: ISegmentItem) => item.id === activeId)}
            index={items.find((item: ISegmentItem) => item.id === activeId)}
            onDelete={onDelete}
            endpoints={endpoints}
            isDragging
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};
