import { useState } from "react";

import { color } from "metabase/lib/colors";
import { useSelector } from "metabase/lib/redux";
import { getCohortsValues } from "metabase/query_builder/selectors";
import { Icon, Modal, Title } from "metabase/ui";

import type { ICohortItem } from "../../types/interfaces";
import { CancelButton } from "../CancelButton";
import { ModalCheckboxes } from "../ModalCheckboxes";
import { SaveButton } from "../SaveButton";

import {
  DashboardModalFooter,
  DashboardModalHeader,
  DashboardTextInput,
  EventsModalContent,
  ModalCloseButton,
  ModalContent,
  StyledLoader,
} from "./CohortsModal.styled";

interface IFilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  additionalCancelOption?: boolean;
  onSave: (cardId: string, properties: ICohortItem[]) => void;
  cardId: string;
}

interface ICohortValue {
  id: number;
  display: number;
  load_date: string;
}

export const CohortsModal = ({
  isOpen,
  onClose,
  additionalCancelOption,
  onSave,
  cardId,
}: IFilterModalProps) => {
  const cohortValues = useSelector(getCohortsValues);
  const [searchValue, setSearchValue] = useState("");
  const [activeFilters, setActiveFilters] = useState<ICohortItem[]>([]);
  const filteredCheckboxes = cohortValues?.filter((item: ICohortValue) =>
    item?.display?.toString().toLowerCase().includes(searchValue.toLowerCase()),
  );

  return (
    <Modal.Root opened={isOpen} onClose={() => setSearchValue("")}>
      <Modal.Overlay />
      <ModalContent>
        <DashboardModalHeader>
          <Title order={2} size="1rem" lh="1rem" color="inherit">
            Select cohort
          </Title>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setSearchValue("");
            }}
          />
        </DashboardModalHeader>
        <DashboardTextInput
          type="search"
          icon={<Icon name="search" size={16} />}
          miw={400}
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          placeholder={"Search…"}
        />
        <EventsModalContent>
          {cohortValues || cohortValues?.length > 0 ? (
            <ModalCheckboxes
              onCheckboxChange={cards => setActiveFilters(cards)}
              values={filteredCheckboxes}
            />
          ) : (
            <StyledLoader size="lg" c={color("brand")} />
          )}
        </EventsModalContent>
        <DashboardModalFooter fullPageModal={false} formModal={true}>
          {additionalCancelOption && <CancelButton />}
          <SaveButton
            onClick={() => {
              onSave(cardId, activeFilters);
              onClose();
            }}
          />
        </DashboardModalFooter>
      </ModalContent>
    </Modal.Root>
  );
};
