import { useDispatch, useSelector } from "metabase/lib/redux";
import { clearEventGroup } from "metabase/query_builder/actions";
import {
  getCurrentEvents,
  getDashboardMode,
} from "metabase/query_builder/selectors";
import { Icon } from "metabase/ui";

import { DashboardMode } from "../../types/enum";
import type { IGroupCard, IProperty } from "../../types/interfaces";
import { removeDoubleQuotes } from "../../utils/removeDoubleQuotes";

import {
  CardContentColumn,
  CardContentDeleteButton,
  CardContentItem,
  CardContentText,
  CardContentTitle,
  CardContentWrapper,
  IconWrapper,
  StyledIcon,
} from "./CardContent.styled";

interface ICardContentProps {
  data: IProperty[];
  onParamsClick: (propertyId: string) => void;
  onPropertyDelete: (cardId: number, propertyId: string) => void;
  cardId: string;
  cardUniqueId: number;
}

interface ICurrentOption {
  display: string;
}

export const CardContent = ({
  data,
  onParamsClick,
  onPropertyDelete,
  cardUniqueId,
}: ICardContentProps) => {
  const dispatch = useDispatch();
  const dashboardMode = useSelector(getDashboardMode);
  const currentEvent = useSelector(getCurrentEvents).find(
    (item: IGroupCard) => item.cardUniqueId === cardUniqueId,
  );

  const currentOptions: Set<ICurrentOption> =
    currentEvent &&
    new Set([
      ...currentEvent?.groupList?.properties,
      ...currentEvent?.groupList?.frequentlyUsedProperties,
    ]);

  const handleOptionDeleteClick = () => {
    dispatch(
      clearEventGroup({
        eventId: cardUniqueId,
        groupData: {
          properties: [],
          frequentlyUsedProperties: [],
        },
      }),
    );
  };

  return (
    <CardContentWrapper>
      {data.map((item, index) => {
        return (
          <CardContentItem key={index}>
            <CardContentColumn onClick={() => onParamsClick(item.id)}>
              <CardContentTitle hasPointer={true}>
                {dashboardMode === DashboardMode.Segmentation && (
                  <IconWrapper>
                    <Icon name="pulse" size={10} color="white" />
                  </IconWrapper>
                )}
                {item.display}
              </CardContentTitle>
              {item.condition}{" "}
              {item?.values?.map((value, index, array) => {
                return `${removeDoubleQuotes(value.display)}${
                  index < array.length - 1 ? ", " : ""
                }`;
              })}
            </CardContentColumn>
            <CardContentColumn>
              <CardContentDeleteButton>
                <StyledIcon
                  name={"close"}
                  size={16}
                  onClick={() => {
                    onPropertyDelete(cardUniqueId, item.id);
                  }}
                />
              </CardContentDeleteButton>
            </CardContentColumn>
          </CardContentItem>
        );
      })}
      {currentOptions && currentOptions.size > 0 && (
        <CardContentItem>
          <CardContentColumn>
            <CardContentTitle hasPointer={false}>Grouped by</CardContentTitle>
            <CardContentText hasPointer={false}>
              {Array.from(currentOptions).map((value, index, array) => (
                <p style={{ textAlign: "left", margin: 0 }} key={index}>
                  {value.display}
                  {index < array.length - 1 ? ", " : " "}
                </p>
              ))}
            </CardContentText>
          </CardContentColumn>
          <CardContentColumn>
            <CardContentDeleteButton>
              <StyledIcon
                name={"close"}
                size={16}
                onClick={() => {
                  handleOptionDeleteClick();
                }}
              />
            </CardContentDeleteButton>
          </CardContentColumn>
        </CardContentItem>
      )}
    </CardContentWrapper>
  );
};
