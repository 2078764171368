import styled from "@emotion/styled";

import { color } from "metabase/lib/colors";

export const GroupContainerWrapper = styled.div`
  padding: 1rem;
  padding-left: 2rem;

  &:not(:last-child) {
    border-bottom: 1px solid ${color("border")};
  }
`;
