import { useEffect, useState } from "react";

import CheckBox from "metabase/core/components/CheckBox";
import { color } from "metabase/lib/colors";
import { useDispatch, useSelector } from "metabase/lib/redux";
import {
  setEventsPropertiesValues,
  updatePropertyCondition,
} from "metabase/query_builder/actions";
import {
  getCurrentEvents,
  getEventsPropertyValues,
} from "metabase/query_builder/selectors";
import { Icon, Modal, Title } from "metabase/ui";
import type { NativeDatasetQuery } from "metabase-types/api";

import { usePostEventsNativeQueryMutation } from "../../api/dashboardApi";
import type {
  IColsItem,
  IEventsEndpoints,
  IGroupCard,
  IProperty,
} from "../../types/interfaces";
import { mapApiData } from "../../utils/mapApiData";
import { CancelButton } from "../CancelButton";
import { ModalCheckboxes } from "../ModalCheckboxes";
import { SaveButton } from "../SaveButton";

import {
  CheckboxContainer,
  ClearButton,
  ConditionContent,
  ConditionTitle,
  CopyButton,
  DashboardModalFooter,
  DashboardTextInput,
  ModalConditionColumn,
  ModalContent,
  ModalListItem,
  ModalSelectedColumn,
  ModalWrapper,
  SelectedContent,
  SelectedGroup,
  SelectedHeader,
  SelectedTitle,
  StyledLoader,
  StyledModalList,
} from "./ParamsModal.styled";

interface IGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  additionalCancelOption?: boolean;
  cardId: string;
  propertyId: string;
  endpoints: IEventsEndpoints;
  cardUniqueId: number;
}

export const ParamsModal = ({
  isOpen,
  onClose,
  additionalCancelOption,
  cardId,
  propertyId,
  endpoints,
  cardUniqueId,
}: IGroupModalProps) => {
  const [postNativeQuery] = usePostEventsNativeQueryMutation();
  const dispatch = useDispatch();
  const currentEvents = useSelector(getCurrentEvents);
  const currentCondition = currentEvents
    ?.find((item: IGroupCard) => item.cardUniqueId === cardUniqueId)
    ?.properties?.find(
      (property: IProperty) => property.id === propertyId,
    )?.condition;

  const currentValues = currentEvents
    ?.find((item: IGroupCard) => item.cardUniqueId === cardUniqueId)
    ?.properties?.find(
      (property: IProperty) => property.id === propertyId,
    )?.values;

  const eventsPropertyValues = useSelector(getEventsPropertyValues);
  const [propertyCondition, setPropertyCondition] = useState("=");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const filteredProperties = eventsPropertyValues?.filter(item =>
    item?.display
      ?.toString()
      ?.toLowerCase()
      ?.includes(searchValue.toLowerCase()),
  );

  const conditionOptions = [
    {
      display: "= (is)",
      value: "=",
    },
    {
      display: "≠ (is not)",
      value: "≠",
    },
    // {
    //   display: "starts with",
    //   value: "starts with",
    // },
    // {
    //   display: "does not start with",
    //   value: "does not start with",
    // },
    // {
    //   display: "∋ (contains)",
    //   value: "∋",
    // },
    // {
    //   display: "∌ (does not contain)",
    //   value: "∌",
    // },
    {
      display: "< (less)",
      value: "<",
    },
    {
      display: "≤ (less or equal)",
      value: "≤",
    },
    {
      display: "> (greater)",
      value: ">",
    },
    {
      display: "≥ (greater or equal)",
      value: "≥",
    },
    // {
    //   display: "{=} (set is)",
    //   value: "{=}",
    // },
    // {
    //   display: "{≠} (set is not)",
    //   value: "{≠}",
    // },
    // {
    //   display: "{∋} (set contains)",
    //   value: "{∋}",
    // },
    // {
    //   display: "{∌} (set does not contain)",
    //   value: "{∌}",
    // },
    // {
    //   display: "✱ (glob match)",
    //   value: "✱",
    // },
    // {
    //   display: "!✱ (glob does not match)",
    //   value: "!✱",
    // },
  ];

  const handleChange = (value: string) => {
    setSelectedOptions(prevSelected => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter(item => item !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  const handleClearClick = () => {
    setSelectedOptions([]);
  };

  const handleSelectedPropertiesChange = (values: string[]) => {
    setSelectedProperties(values);
  };

  const handleSaveClick = () => {
    dispatch(
      updatePropertyCondition({
        cardId,
        propertyId,
        propertyCondition,
        selectedOptions,
        selectedProperties,
        cardUniqueId,
      }),
    );
    onClose();
  };

  const fetchParamsData = async () => {
    const payloadQuery = `{
      "path": "${endpoints.propertyValues}",
      "method": "post",
      "body": {
        "property": "${propertyId}",
        "event_type": "${cardId}"
      },
      "headers": {}
    }`;
    const nativeQuery: NativeDatasetQuery = {
      native: {
        query: payloadQuery,
      },
      type: "native",
      database: 2,
    };
    try {
      const response = await postNativeQuery(nativeQuery).unwrap();
      const formattedKeys = response?.data?.cols?.map(
        (col: IColsItem) => col.name,
      );
      const formattedValues = response?.data?.rows;
      const eventsData = mapApiData(formattedKeys, formattedValues);

      dispatch(setEventsPropertiesValues(eventsData));
    } catch (error) {
      console.error("Failed to post data:", error);
    }
  };

  useEffect(() => {
    fetchParamsData();
  }, [propertyId]);

  useEffect(() => {
    if (isOpen && currentCondition) {
      setPropertyCondition(currentCondition);
    }
  }, [isOpen, currentCondition]);

  return (
    <Modal.Root opened={isOpen} onClose={() => console.log("close")}>
      <Modal.Overlay />
      <ModalContent>
        <ModalWrapper>
          <ModalConditionColumn>
            <ConditionTitle order={2} size="1rem" lh="1rem" color="inherit">
              Сondition
            </ConditionTitle>
            <ConditionContent>
              <StyledModalList>
                {conditionOptions.map((option, index) => {
                  return (
                    <ModalListItem
                      isActive={
                        propertyCondition === option.value ? true : false
                      }
                      key={index}
                      onClick={() => setPropertyCondition(option.value)}
                    >
                      {option.display}
                    </ModalListItem>
                  );
                })}
              </StyledModalList>
            </ConditionContent>
          </ModalConditionColumn>
          <ModalSelectedColumn>
            <DashboardTextInput
              type="search"
              icon={<Icon name="search" size={16} />}
              miw={400}
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder={"Search…"}
            />
            <SelectedGroup>
              <SelectedHeader>
                <Title order={2} size="1rem" lh="1rem" color="inherit">
                  Selected
                </Title>
                <ClearButton onClick={handleClearClick}>Clear</ClearButton>
                <CopyButton>Copy Selected</CopyButton>
              </SelectedHeader>
              {eventsPropertyValues &&
                selectedProperties.map((item, index) => {
                  return (
                    <CheckboxContainer key={index}>
                      <CheckBox
                        name={item.display}
                        checked={selectedOptions.includes(item.id)}
                        onChange={() => handleChange(item.id)}
                        aria-label={item.display}
                        label={item.display}
                        uncheckedColor={color("border")}
                      />
                    </CheckboxContainer>
                  );
                })}
            </SelectedGroup>
            <SelectedContent>
              <SelectedTitle order={2} size="1rem" lh="1rem" color="inherit">
                All values
              </SelectedTitle>
              {!eventsPropertyValues || eventsPropertyValues.length === 0 ? (
                <StyledLoader size="lg" c={color("brand")} />
              ) : (
                <ModalCheckboxes
                  onCheckboxChange={handleSelectedPropertiesChange}
                  values={filteredProperties}
                  currentProperties={currentValues}
                  isOpen={isOpen}
                />
              )}
            </SelectedContent>
          </ModalSelectedColumn>
        </ModalWrapper>

        <DashboardModalFooter fullPageModal={false} formModal={true}>
          {additionalCancelOption && <CancelButton onClose={onClose} />}
          <SaveButton onClick={handleSaveClick} />
        </DashboardModalFooter>
      </ModalContent>
    </Modal.Root>
  );
};
