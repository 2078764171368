import { useState } from "react";

import { Icon, Modal, Title } from "metabase/ui";

import type { ITaskData } from "../../types/interfaces";
import { CancelButton } from "../CancelButton";
import { SaveButton } from "../SaveButton";

import {
  DashboardModalFooter,
  DashboardModalHeader,
  DashboardTextInput,
  EventsModalContent,
  ModalCloseButton,
  ModalContent,
  ModalListItem,
  StyledModalList,
} from "./AddTaskModal.styled";

interface IAddTaskModalProps {
  title: string;
  data: ITaskData[];
  isOpen: boolean;
  onClose: () => void;
  onSave: (cardId: string) => void;
  additionalCancelOption?: boolean;
}

export const AddTaskModal = ({
  title,
  data,
  isOpen,
  onClose,
  onSave,
  additionalCancelOption,
}: IAddTaskModalProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [activeEvent, setActiveEvent] = useState<string>("");
  const filteredData = data?.filter(item =>
    item?.display?.toString().toLowerCase().includes(searchValue.toLowerCase()),
  );

  return (
    <Modal.Root opened={isOpen} onClose={() => setSearchValue("")}>
      <Modal.Overlay />
      <ModalContent>
        <DashboardModalHeader>
          <Title order={2} size="1rem" lh="1rem" color="inherit">
            {title}
          </Title>
          <ModalCloseButton
            onClick={() => {
              onClose();
              setSearchValue("");
            }}
          />
        </DashboardModalHeader>
        <DashboardTextInput
          type="search"
          icon={<Icon name="search" size={16} />}
          miw={400}
          value={searchValue}
          onChange={e => {
            setSearchValue(e.target.value);
          }}
          placeholder={"Search…"}
        />
        <EventsModalContent>
          <StyledModalList>
            {data &&
              filteredData.map(item => {
                return (
                  <ModalListItem
                    key={item.id}
                    onClick={() => setActiveEvent(item.id)}
                    isActive={item.id === activeEvent ? true : false}
                  >
                    {item.display}
                  </ModalListItem>
                );
              })}
          </StyledModalList>
        </EventsModalContent>
        <DashboardModalFooter fullPageModal={false} formModal={true}>
          {additionalCancelOption && <CancelButton />}
          <SaveButton
            onClick={() => {
              onSave(activeEvent);
              setSearchValue("");
              onClose();
            }}
          />
        </DashboardModalFooter>
      </ModalContent>
    </Modal.Root>
  );
};
